table,
.table,
.el-table__body{

	.radio,
	.checkbox{
		position: relative;
		height: 20px;
		display: block;
		width: 20px;
		padding: 0px 0px;
		margin: 0px 5px;
	}
	> thead > tr > th,
	> tbody > tr > th,
	> tfoot > tr > th,
	> thead > tr > td,
	> tbody > tr > td,
	> tfoot > tr > td{
		padding: 12px 8px;
		vertical-align: middle;
		border-color: $light-gray;
	}

	> thead > tr > th{
		font-size: $font-size-base;
	}

	table > tr .td-actions{
		display: inline-flex !important;
	}

	.td-actions .btn{
		@include opacity(0.36);

		&.btn-xs{
			padding-left: 3px;
			padding-right: 3px;
		}
	}
	.td-actions{
		min-width: 90px;
	}

	> tbody > tr{
		position: relative;
		font-size: 14px !important;


		&:hover{
			.td-actions .btn{
				@include opacity(1);
			}
		}
	}
	thead th{
		border-bottom: none !important;
		border-top: none !important;
	}

	.btn-info.btn-link{
		color: $info-color;
	}
	.btn-danger.btn-link{
		color: $danger-color;
	}
	.btn-success.btn-link{
		color: $success-color;
	}
	.btn-warning.btn-link{
		color: $warning-color;
	}

	.form-check{
		.form-check-sign,
		.form-check-sign:before,
		.form-check-sign:after{
			margin-top: -14px;
		}
	}
}

.card{
	.table{
		tbody td:first-child,
		thead th:first-child{
			padding-left: 15px;
		}

		tbody td:last-child,
		thead th:last-child{
			padding-right: 15px;
			// display: block;	// breaks colspan!
		}
	}
}

.table-bigboy{
	> thead > tr > th{
		font-size: $font-size-h6;
		text-transform: uppercase;
		color: $dark-gray;
		font-weight: $font-weight-normal;
	}
	> tbody > tr > td{
		font-size: $font-size-base;

		b{
			display: block;
			margin-bottom: 5px;
		}
	}
	.td-name
	{
		font-weight: $font-weight-light;
		font-size: $font-size-h5;
	}
	.td-name{
		min-width: 240px;
	}
	.td-number{
		text-align: right;
		min-width: 110px;

		small{
			margin-right: 3px;
		}
	}

	.td-actions{
		width: 60px;
		min-width: auto;

		.btn{
			float: right;
		}
	}
	.img-container{
		width: 180px;
		height: 120px;
		overflow: hidden;
		display: block;

		border-radius: $border-radius-large;

		img{
			width: 100%;
		}
	}


}

.table-with-links{
	.card-body{
		padding-bottom: 0 !important;

		.table{
			.btn{
				margin-bottom: 0 !important;
			}
			tr td{
				padding: 7px 3px !important;
			}
		}
	}
}


.table-with-switches,
.regular-table-with-color{
	.card-body{
		padding-bottom: 0 !important;

		.table tbody td:last-child{
			padding-right: 10px !important;
		}
	}
}

.table-big-boy{
	.card-body{
		padding-bottom: 0 !important;

		.table tbody td:last-child{
			padding-right: 10px !important;
		}
	}
}

.bootstrap-table{
	.table thead th:first-child{
		padding-left: 0!important;
	}
	.dropdown-menu{
		li{
			a{
				display: block;
				padding: 8px 16px;
				clear: both;
				font-weight: 400;
				line-height: 1.42857143;
				white-space: nowrap;
				color: #333;

				&:hover{
					background-color: #F5F5F5;
				}
			}
			&.active{
				a{
					background-color: rgba(68, 125, 247, 0.2);
				}
			}

		}

	}
}
.data-tables{
	table{
		tbody{
			.btn{
				padding: 3px !important;
				font-size: 16px !important;
			}
		}
	}
	.pagination{
		float: right;
	}
	tfoot{
		font-size: 14px;
	}
}

.card-tasks{
	.table tbody{
		td:last-child{
			padding-right: 0;
			display: inline-flex;
			.btn{
				padding: 3px;
			}
		}
		td:first-child{
			padding-left: 0;
		}
	}
	.table-full-width{
		padding-bottom: 0 !important;
	}
	.card-footer{
		padding-top: 0;
	}
	.table{
		margin-bottom: 0 !important;
	}
}

.table-full-width{
	margin-left: -15px;
	margin-right: -15px;
	padding: 15px !important;
}




@include table-row-variant('success', $light-green);
@include table-row-variant('info', $light-azure);
@include table-row-variant('warning', $light-orange);
@include table-row-variant('danger', $light-red);

<template>
	<card :title="localize('BingoBooster Rooms')">
		<a
			class="btn btn-add float-right"
			@click.prevent="addItem()"
			:title="localize('Add new Item') + ' (' + localize('Shortcut') + ': Ctrl + )'"
		>
			<icon :icon="['far', 'plus']" fixed-width size="lg" />
		</a>
		<b-table
			:items="bingoBoosterRoomList"
			:fields="fields"
			:sort-by="'id'"
			:sort-desc="false"
			show-empty
			striped
			:empty-text="emptyResultText"
			:label-sort-asc="localize('Click to sort Ascending')"
			:label-sort-desc="localize('Click to sort Descending')"
			:empty-filtered-text="localize('There are no items matching your request')"
		>
			<template v-slot:cell(status)="row">
				<span>{{ localize(bingoBoosterRoomStateEnum[(row.item.status || {}).state]) }}</span>
			</template>
			<template v-slot:cell(actions)="row">
				<a
					v-b-tooltip.hover.top="localize('Edit')"
					class="btn-info btn-link"
					@click.stop="editItem(row.item)"
				>
					<icon :icon="['far', 'edit']" fixed-width size="lg" />
				</a>
				<a
					v-b-tooltip.hover.top="localize('Delete')"
					class="btn-danger btn-link"
					@click.stop="deleteItem(row.index, row.item)"
				>
					<icon :icon="['far', 'trash-alt']" fixed-width size="lg" />
				</a>
			</template>
		</b-table>
	</card>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import { BingoBoosterRoomService } from '@/services';
import Card from '@/components/lbd/Cards/Card.vue';
import { BingoBoosterHall, IBingoBoosterRoom as BingoBoosterRoom, Room } from '@/services/api';


@Component({
	name: 'BingoBoosterRoomList',
	components: {
		Card
	}
})
export default class BingoBoosterRoomList extends BaseList {
	private bingoBoosterRoomService: BingoBoosterRoomService = new BingoBoosterRoomService();

	public created() {
	}

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: true
		},
		{
			key: "roomId",
			label: this.localize("Room"),
			formatter: this.roomIdFormatter
		},
		{
			key: "bingoBoosterHallIds",
			label: this.localize("BingoBooster Halls"),
			formatter: this.bingoBoosterHallIdsFormatter
		},
		{
			key: "enabled",
			label: this.localize("Enabled"),
			sortable: true
		},
		{
			key: "actions",
			label: this.localize("Actions")
		}
	];

	private bingoBoosterHallIdsFormatter(value: Array<number>, key: string, item: BingoBoosterRoom): string {
		return (this.bingoBoosterHallList || new Array<BingoBoosterHall>())
			.filter((hall) => value.includes(hall.id))
			.map(hall => hall.name)
			.reduce((a, b) => a + "," + b, "")
			.substring(1);
	}

	private roomIdFormatter(value: number, key: string, item: BingoBoosterRoom): string {
		if(!value) return undefined;
		let room = (this.roomList || new Array<Room>())
			.find(room => room.id == value);
		if(room) return room.name;
		return undefined;
	}


}
</script>

<style scoped>

.controls{
	margin: 0 12px;
}

a[disabled] {
	cursor: default;
	color:#424242;
}

.btn-link[disabled] {
	color:#424242 !important;
}

</style>

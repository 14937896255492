<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div class="modal-body">
						<button type="submit" class="btn btn-outline float-right" @click.prevent="$emit('close')">{{ localize("Close") }}</button>
						<card :title="localize('Add Block')">
							<b-table
								:items="blockList"
								:fields="fields"
								show-empty
								striped
								:empty-text="emptyResultText"
								:label-sort-asc="localize('Click to sort Ascending')"
								:label-sort-desc="localize('Click to sort Descending')"
								:empty-filtered-text="localize('There are no items matching your request')"
							>
								<template v-slot:cell(color)="row">
									<div
										class="colorPatch"
										:style="{
											background: getBlockColor(row.value)
										}"
									> </div>
								</template>
								<template v-slot:cell(actions)="row">
									<a
										v-b-tooltip.hover.top="localize('Pick')"
										class="btn btn-primary btn-fill"
										@click.stop="pickItem(row.index, row.item)"
									>
										<icon :icon="['far', 'plus']" fixed-width size="lg" />
									</a>
								</template>
							</b-table>
						</card>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError, ColorUtilities, TimeSpan } from '@/utilities';
import { LogLevel, IBlock } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import { ISheetType as SheetType, IGridType as GridType, IGame as Game, GameBlock, LinkedRoomBlock, BlockType, LoopingGamesBlock } from '@/services/api';

let tinycolor = require("tinycolor2");


@Component({
	name: 'block-picker',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"fg-input": fgInput
	}
})
export default class BlockPicker extends BaseForm {

	protected get loadItem(): (itemId: number) => void {
		return ((itemId: number) => {});	// Prevent loading this.item
	}

	private fields: Array<any> = [
		{
			key: "name",
			label: this.localize("Name"),
			sortable: false
		},
		{
			key: "color",
			label: this.localize("Color"),
			sortable: false
		},
		{
			key: "numberOfGames",
			label: this.localize("Games"),
			formatter: this.numberOfGamesFormatter,
			sortable: false
		},
		{
			key: "duration",
			label: this.localize("Duration"),
			formatter: this.durationFormatter,
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private numberOfGamesFormatter(value: any, key: string, item: any): string {
		if(item && item.gameIds && Array.isArray(item.gameIds)) return item.gameIds.length + "";
		return "0";
	}

	private durationFormatter(value: Array<number>, key: string, item: GameBlock): string {
		let dur: TimeSpan = new TimeSpan();
		if(item && item.duration) dur = TimeSpan.parse(item.duration);
		return dur.toString("HH:MM:SS");
	}

	private getBlockColor(value: number): string {
		let tColor = tinycolor(ColorUtilities.argbToVueColor(value).rgba);
		return tColor.toHexString();
	}

	/** @method saveItem Saves the the item operated on by this form, then navigates back to the list for this item. */
	protected saveItem() : void{
		this.$emit("addBlock", this.item);
	}

	private pickItem(index: number, item: IBlock): void {
		this.item = item.id;
		this.saveItem();
	}

	private get blockList(): Array<IBlock> {
		let completeList: Array<any> = new Array<any>();
		if(this.gameBlockList && Array.isArray(this.gameBlockList) && this.gameBlockList.length > 0) completeList.push(...this.gameBlockList);
		if(this.linkedRoomBlockList && Array.isArray(this.linkedRoomBlockList) && this.linkedRoomBlockList.length > 0) completeList.push(...this.linkedRoomBlockList);
		if(this.loopingGamesBlockList && Array.isArray(this.loopingGamesBlockList) && this.loopingGamesBlockList.length > 0) completeList.push(...this.loopingGamesBlockList);
		return completeList;
	}

}
</script>

<style scoped>

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-container {
	width: 80%;
	margin: 0px auto;
	padding: 20px 30px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
	transition: all .3s ease;
}

.modal-body {
	margin: 0;
}

.modal-default-button {
	float: right;
}

/*
 * The following styles are auto-applied to elements with transition="modal" when their visibility is toggled by Vue.js.
 * You can easily play with the modal transition by editing these styles.
 */

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

/* .el-select-dropdown {
	z-index: 12005 !important;
} */

.colorPatch{
	width:100%;
	height:100%;
	min-height:20px;
}

</style>

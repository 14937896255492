<template>
	<card :title="localize('Users')">
		<a
			class="btn btn-add float-right"
			@click.prevent="addItem()"
			:title="localize('Add new Item') + ' (' + localize('Shortcut') + ': Ctrl + )'"
		>
			<icon :icon="['far', 'plus']" fixed-width size="lg" />
		</a>
		<b-table
			:items="userList"
			:fields="fields"
			:sort-by="'id'"
			:sort-desc="false"
			show-empty
			striped
			:empty-text="emptyResultText"
			:label-sort-asc="localize('Click to sort Ascending')"
			:label-sort-desc="localize('Click to sort Descending')"
			:empty-filtered-text="localize('There are no items matching your request')"
		>
			<template v-slot:cell(actions)="row">
				<a
					v-b-tooltip.hover.top="localize('Edit')"
					class="btn-info btn-link"
					@click.stop="editItem(row.item)"
				>
					<icon :icon="['far', 'edit']" fixed-width size="lg" />
				</a>
				<a
					v-b-tooltip.hover.top="localize('Delete')"
					class="btn-danger btn-link"
					@click.stop="deleteItem(row.index, row.item)"
				>
					<icon :icon="['far', 'trash-alt']" fixed-width size="lg" />
				</a>
			</template>
		</b-table>
	</card>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel, IUser as User } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';


@Component({
	name: 'UserList',
	components: {
		Card
	}
})
export default class UserList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "username",
			label: this.localize("Username"),
			sortable: false
		},
		{
			key: "role",
			label: this.localize("Role"),
			sortable: false
		},
		{
			key: "email",
			label: this.localize("Email"),
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

}
</script>

<style scoped>

.controls{
	margin: 0 12px;
}

</style>

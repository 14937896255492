
// Include Bootstrap and BootstrapVue SCSS files
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-vue/src/index.scss';


.logo-img img {
	position: relative;
	top:-12px;
	left:-12px;
	width:40px;
	max-width: 44px !important;
}

// .sidebar .nav .nav-item .nav-link {
// 	margin: 3px 0 3px 15px !important;
// 	padding: 0 !important;
// 	text-transform: none !important;
// }

// .sidebar .sidebar-wrapper .nav .nav-link p {
//     text-transform: none !important;
// }

.card{
	margin-top:31px !important;
}

.card .card-header {
	padding: 15px 0px 0px 15px;
}

.card-header{
	position:absolute;
	width:100%;
	left:-15px;
	top:-60px !important;
	background: transparent !important;
}

.card .card .card-header{
	position: relative !important;
	left:0px;
	top:0 !important;
}

.card-header .btn-close{
	position: absolute;
	top:10px;
	right:-20px;
	padding: 4px 4px 4px 12px;
}

.btn-add{
	padding: 7px 11px;
	background: #009FE3 !important;
	color:#fff !important;
	cursor: pointer;
	border: 1px solid #fff;
}

.btn-close svg{
	color:#fff !important;
	background: rgb(175, 52, 25) !important;
	border:solid 1px rgb(175, 52, 25);
	border-radius: 5px;
	cursor: pointer;
}

.btn-close .svg-inline--fa.fa-fw {
	width:32px;
	height:32px;
}

.btn-cancel{
	opacity: 0.3;
	border: solid 1px #000;
}

table{
	margin-top:36px;
}

button{
	margin-left:0 !important;
	margin-right:0 !important;
}

.form-button-container {
	margin: auto 0 18px 0;
}

button>i.fa{
	margin-left:-6px;
	margin-right: 3px;
}

.b-table tbody tr td a.btn-link {
    &:first-child {
        margin-left: 0;
    }

    margin-left: 35px;
}

input:required,
textarea:required,
div.el-select[required] div.el-input input,
div.el-select[required]:not([invalid]) div.el-input input{
  border-left: 3px solid;
  border-radius: 5px;
  border-left-color: rgb(120, 148, 95) !important;
}

input:invalid,
textarea:invalid,
div.el-select[invalid] div.el-input input{
  border-left-color: rgb(175, 52, 25) !important;
}

.percentage{
	min-width:60px;
}

input[type='time']{
	min-width: 96px;
}

// Actions

.btn-action{
	border:none;
	padding: 8px 8px;
	color: #fff;
	cursor: pointer;
	margin: 0;
}

.btn-action,
.btn-action:hover,
.btn-action:link,
.btn-action:default,
.btn-action:focus,
.btn-action:active{
	background-color: transparent !important;
	border:none !important;
}


// Help

// Tooltip

.tooltip {
	.tooltip-inner {
		max-width: 300px !important;
		background-color: #009FE3 !important;
	}
	.tooltip-inner:before {
		border-bottom-color: #009FE3 !important;
	}
}

.bs-tooltip-top{
	.arrow::before{
		border-top-color: #009FE3 !important;
	}
}

.bs-tooltip-right{
	.arrow::before{
		border-right-color: #009FE3 !important;
	}
}

.longTooltip{
	.tooltip-inner {
		max-width: 400px !important;
	}
}

.veryLongTooltip{
	.tooltip-inner {
		max-width: 500px !important;
	}
}
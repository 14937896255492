<template>
	<component :is="baseComponent"
		:to="link.path ? link.path : '/'"
		class="nav-item"
		:class="{active : isActive}"
		tag="li"
	>
		<a v-if="isMenu"
			class="nav-link sidebar-menu-item"
			:aria-expanded="!collapsed"
			data-toggle="collapse"
			@click="collapseMenu"
		>
			<icon :icon="['fal', link.icon]" fixed-width size="2x" />
			<p>
				{{link.name}}
				<b class="caret"></b>
			</p>
		</a>
		<div v-if="$slots.default || this.isMenu">
			<ul class="nav" v-show="!collapsed">
				<slot></slot>
			</ul>
		</div>
		<slot name="title" v-if="children.length === 0 && !$slots.default && link.path">
			<component
				:to="link.path"
				@click.native="linkClick"
				:is="elementType(link, false)"
				:class="{active: link.active}"
				class="nav-link"
			>
				<!-- This breaks "open in new tab" for relative paths
				:target="link.target"
				:href="link.path">
				-->
				<template v-if="addLink">
					<span class="sidebar-mini">{{link.name.substring(0, 1)}}</span>
					<span class="sidebar-normal">{{link.name}}</span>
				</template>
				<template v-else>
					<icon :icon="['fal', link.icon]" fixed-width size="2x" />
					<p>{{link.name}}</p>
				</template>
			</component>
		</slot>
	</component>
</template>

<script lang="ts">
import { Vue, Component, Prop, Inject, Provide } from 'vue-property-decorator';
import {Link } from './link';
import CollapseTransition from '../Transitions/collapse-transition';
//import CollapseTransition from 'element-ui/lib/Transitions/collapse-transition';



@Component({
	name: "sidebar-item",
	components: {
		[CollapseTransition.name]: CollapseTransition
	}
})
export default class SidebarItem extends Vue {

	children: Array<SidebarItem> = [];
	collapsed: boolean = true;
	providedLinkAdder: (item: SidebarItem) => {} = null;
	providedLinkRemover: (item: SidebarItem) => {} = null;

	@Prop({ type: Boolean, default: false })
	menu: boolean = false;

	@Prop({
		type: Object,
		default: new Link("", "", [])
	})
	link: Link;

	@Provide("addLink")
	addChild(item: SidebarItem) {
		const index = this.$slots.default.indexOf(item.$vnode);
		this.children.splice(index, 0, item);
	}

	@Provide("removeLink")
	removeChild(item: SidebarItem) {
		const tabs = this.children;
		const index = tabs.indexOf(item);
		tabs.splice(index, 1);
	}

	@Inject({ default: null })
	get addLink(): (item: SidebarItem) => {} {
		return this.providedLinkAdder;
	}
	set addLink(providedLinkAdder: (item: SidebarItem) => {}) {
		this.providedLinkAdder = providedLinkAdder;
	}

	@Inject({ default: null })
	get removeLink(): (item: SidebarItem) => {} {
		return this.providedLinkRemover;
	}
	set removeLink(providedLinkRemover: (item: SidebarItem) => {}) {
		this.providedLinkRemover = providedLinkRemover;
	}

	@Inject({ default: true })
	autoClose: boolean;


	get baseComponent(){
		return this.isMenu || this.link.isRoute ? "li" : "router-link";
	}

	get isMenu() {
		return this.children.length > 0 || this.menu === true;
	}

	get isActive() {
		if (this.$route && this.$route.path) {
			let matchingRoute = this.children.find((c: SidebarItem) =>
				this.$route.path.startsWith(c.link.path)
			);
			if (matchingRoute !== undefined) {
				return true;
			}
		}
		return false;
	}

	mounted() {
		if (this.addLink) {
			this.addLink(this);
		}
		if (this.link.collapsed !== undefined) {
			this.collapsed = this.link.collapsed;
		}
		if (this.isActive && this.isMenu) {
			this.collapsed = false;
		}
	}

	destroyed() {
		if (this.$el && this.$el.parentNode) {
			this.$el.parentNode.removeChild(this.$el);
		}
		if (this.removeLink) {
			this.removeLink(this);
		}
	}

	elementType(link: Link, isParent: boolean = true) {
		if (link.isRoute === false) {
			return isParent ? "li" : "a";
		} else {
			return "router-link";
		}
	}

	linkAbbreviation(name: string) {
		const matches = name.match(/\b(\w)/g);
		return matches.join("");
	}

	linkClick() {
		if (
			this.autoClose &&
			(<any>this).$sidebar &&
			(<any>this).$sidebar.showSidebar === true
		) {
			(<any>this).$sidebar.displaySidebar(false);
		}
	}

	collapseMenu() {
		this.collapsed = !this.collapsed;
	}

	collapseSubMenu(link: Link) {
		link.collapsed = !link.collapsed;
	}

}

</script>

<style scoped>

.sidebar-menu-item {
	cursor: pointer;
}

.svg-inline--fa {
	vertical-align:middle;
}

.sidebar .sidebar-wrapper .nav .nav-link p {
	display:inline-block;
	margin:0 0 0 12px;
}

.sidebar .nav .nav-item .nav-link {
    margin: 0;
    padding: 5px 15px;
}
</style>

<template>
	<card :title="title">
		<template v-slot:cell(header) class="form-header">
			<h4 class="card-title">{{ title }}</h4>
			<a
				class="btn-close"
				@click.prevent="closeForm()"
				:title="localize('Close') + ' (' + localize('Shortcut') + ': Esc)'"
			>
				<icon :icon="['far', 'times']" fixed-width size="lg" border />
			</a>
		</template>
		<form @submit.prevent="saveItem">
			<div class="row">
				<div class="col-6">
					<fg-input
						type="text"
						:label="localize('Name')"
						:placeholder="localize('text')"
						v-model="item.name"
						required
						aria-required
					></fg-input>
					<fg-input
						:label="localize('Description')"
					>
						<el-input
							type="textarea"
							:rows="4"
							:placeholder="localize('text')"
							v-model="item.description"
							required
							aria-required
						></el-input>
					</fg-input>
					<div>
						<label class="control-label">{{ localize("Game Type") }}</label>
						<br>
						<el-select
							class="form-group select-default"
							style="width:100%"
							size="large"
							:placeholder="localize('Choose')"
							v-model="item.isProbabilityGame"
							:no-data-text="emptyResultText"
							:loading-text="localize('loading...')"
							:disabled="item.id > 0"
							required
							aria-required
						>
							<el-option
								class="select-default"
 								role="option"
								:label="localize('Revenue based Game')"
								:value="false"
								:aria-selected="item.isProbabilityGame == false"
							></el-option>
							<el-option
								class="select-default"
 								role="option"
								:label="localize('Probability based Game')"
								:value="true"
								:aria-selected="item.isProbabilityGame == true"
							></el-option>
						</el-select>
					</div>
					<div>
						<label class="control-label">{{ localize("Grid Type") }}</label>
						<br>
						<el-select
							class="form-group select-default"
							style="width:100%"
							size="large"
							:placeholder="localize('Choose')"
							v-model="item.gridTypeId"
							:no-data-text="emptyResultText"
							:loading-text="localize('loading...')"
							:disabled="item.gridTypeId > 0"
							required
							aria-required
							:invalid="!(item.gridTypeId>0)"
						>
							<el-option
								class="select-default"
 								role="option"
 								v-for="type in gridTypeList"
								:key="type.id"
								:value="type.id"
								:aria-label="localize('Grid Type') + ': ' + getGridTypeName(type)"
								:aria-selected="item.gridTypeId == type.id"
								:label="getGridTypeName(type)"
							></el-option>
						</el-select>
					</div>
				</div>
				<div class="col-6">
					<label class="control-label">{{ localize("Color") }}</label>
					<swatches-picker :value="color" v-model="color" />
				</div>
			</div>
			<div
				class="row"
				v-if="item.gridTypeId > 0"
			>
				<div class="col-6">
					<label class="control-label">{{ localize("Sheet Types") }}</label>
					<br>
					<el-select
						class="form-group select-default"
						style="width:100%"
						size="large"
						aria-multiselectable
						multiple
						:placeholder="localize('Choose')"
						v-model="sheetTypeIds"
						:no-data-text="emptyResultText"
						:loading-text="localize('loading...')"
						required
						aria-required
						:invalid="!sheetTypeIds || !Array.isArray(sheetTypeIds) || sheetTypeIds.length == 0"
					>
						<el-option
							class="select-default"
							role="option"
							v-for="type in filteredSheetTypeList"
							:key="type.id"
							:value="type.id"
							:aria-label="localize('Sheet Type') + ': ' + getSheetTypeName(type)"
							:aria-selected="item.sheetTypeIds.includes(type.id)"
							:label="getSheetTypeName(type)"
						>
							<div class="color" :style="{
								backgroundColor: getRgba(type.color)
							}"></div><div class="colorId"> {{ getSheetTypeName(type) }}</div>
						</el-option>
					</el-select>
				</div>
			</div>
			<prize-list
				v-if="item && item.gridTypeId > 0"
				:item="item"
				:isProbabilityGame="item.isProbabilityGame"
				v-on:change="prizesChanged"
			></prize-list>
			<div class="row">
				<div cass="col-6 duration" style="padding-left:15px;">
					<label class="control-label">{{ localize("Purchase time") }}</label>
					<br/>
					<vue-timepicker
						v-model="defaultPurchaseTime"
						format="mm:ss"
						:minute-interval="1"
						:second-interval="5"
						:hour-range="[[0, 0]]"
						:hour-label="localize('Hours')"
						:minute-label="localize('Minutes')"
						:second-label="localize('Seconds')"
						close-on-complete
						required
					></vue-timepicker>
				</div>
				<div cass="col-6 duration" style="padding-left:15px;">
					<label class="control-label">{{ localize("Max Duration") }}</label>
					<br/>
					<vue-timepicker
						v-model="item.maxDuration"
						format="HH:mm:ss"
						:hour-range="[[1, 24]]"
						:hour-label="localize('Hours')"
						:minute-label="localize('Minutes')"
						:second-label="localize('Seconds')"
						close-on-complete
						disabled
					></vue-timepicker>
				</div>
			</div>
			<card
				v-if="item && item.gridTypeId > 0"
				:title="localize('Draw')"
			>
				<div class="row">
					<fg-input
						class="col-4"
						type="number"
						min="1"
						max="90"
						step="1"
						:label="localize('Total number of draws')"
						:placeholder="localize('<number>')"
						v-model="drawInfo.totalDraws"
						required
						aria-required
					></fg-input>
					<fg-input
						class="col-4"
						type="number"
						min="1"
						max="10"
						step="0.1"
						:label="localize('Draw Delay')"
						:placeholder="localize('<seconds>')"
						v-model="normalDrawDelay"
						required
						aria-required
					></fg-input>
					<fg-input class="col-4" :label="localize('Letters')">
						<checkbox v-model="drawInfo.showLetters">{{ localize("Show Letters") }}</checkbox>
					</fg-input>
				</div>
				<card :title="localize('Initial Draws')">
					<div class="row">
						<fg-input
							class="col-4"
							type="number"
							min="0"
							max="90"
							step="1"
							:label="localize('Number of initial draws')"
							:placeholder="localize('<number>')"
							v-model="drawInfo.firstDraws"
							required
							aria-required
						></fg-input>
						<fg-input
							class="col-4"
							type="number"
							min="0"
							max="10"
							step="0.1"
							:label="localize('Initial draw delay')"
							:placeholder="localize('<seconds>')"
							v-model="firstDrawDelay"
							required
							aria-required
						></fg-input>
					</div>
				</card>
				<card :title="localize('Suspense Draws')">
					<div class="row">
						<fg-input
							class="col-4"
							type="number"
							min="0"
							max="90"
							step="1"
							:label="localize('Number of suspense draws')"
							:placeholder="localize('<number>')"
							v-model="drawInfo.lastDraws"
							required
							aria-required
						></fg-input>
						<fg-input
							class="col-4"
							type="number"
							min="0"
							max="10"
							step="0.1"
							:label="localize('Suspense draw delay')"
							:placeholder="localize('<seconds>')"
							v-model="lastDrawDelay"
							required
							aria-required
						></fg-input>
					</div>
				</card>
				<card :title="localize('Extra balls')">
					<div class="row">
						<div class="col-2 pt-2">
							<a
								class="btn btn-add mt-4"
								@click.prevent="addExtraBall()"
								:title="localize('Add Extra Ball')"
							>
								<icon :icon="['far', 'plus']" fixed-width size="lg" />
							</a>
						</div>
						<fg-input
							class="col-4"
							type="number"
							min="0"
							max="10"
							step="0.1"
							:label="localize('Extra ball draw delay')"
							:placeholder="localize('<seconds>')"
							v-model="extraBallDrawDelay"
							required
							aria-required
						></fg-input>
					</div>
					<div class="row" v-for="(value, index) in extraBallDrawProbability" :key="index">
						<fg-input
							class="col-4"
							type="number"
							min="0"
							max="100"
							step="0.1"
							:label="localize('Probability for Extra ball') + ' ' + (index + 1) + ' (%)'"
							:placeholder="localize('<%>')"
							v-model="extraBallDrawProbability[index]"
							required
							aria-required
						></fg-input>
					</div>
				</card>
			</card>
			<fg-input class="col-6" :label="localize('Game Client Communication')">
				<checkbox v-model="item.bulkSendGameResult">{{ localize("Send Game Result immedeatly after sales closes") }}</checkbox>
			</fg-input>
			<button
				type="submit"
				class="btn btn-primary btn-fill float-right"
			>{{ localize("Save") }}</button>
			<div class="clearfix"></div>
		</form>
	</card>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Select, Option, Input } from 'element-ui';
import { Swatches } from 'vue-color';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError, ColorUtilities, TimeSpan } from '@/utilities';
import { LogLevel, VueColor } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import PrizeList from './PrizeList.vue';
import { IGame, Game, ISheetType as SheetType, IGridSize as GridSize, IGridType as GridType, Prize, IPrizePattern as PrizePattern, IPrizePatternGroup as PrizePatternGroup, DrawInfo } from '@/services/api';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';


@Component({
	name: 'GameForm',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"el-input": Input,
		"fg-input": fgInput,
		'swatches-picker': Swatches,
		"prize-list": PrizeList,
		VueTimepicker
	}
})
export default class GameForm extends BaseForm {
	private sheetTypeIds: Array<number> = new Array<number>();					//Reactive property for item property that is not a value type (and are not automatically reactive).
	private color: VueColor = new VueColor();									//Reactive property for item property that is not a value type (and are not automatically reactive).
	private filteredSheetTypeList: Array<SheetType> = new Array<SheetType>();	//Reactive property for item property that is not a value type (and are not automatically reactive).
	private drawInfo: DrawInfo = new DrawInfo();
	private extraBallDrawProbability = new Array<number>();

	public created() {
		this.item = new Game();
		this.cloneSheetTypeList(null, null);
		if(this.item && (!this.item.sheetTypeIds || Array.isArray(this.item.sheetTypeIds))) this.item.sheetTypeIds = this.sheetTypeIds;
	}

	@Watch("sheetTypeList")
	private cloneSheetTypeList(val: Array<SheetType>, oldVal: Array<SheetType>): void {
		this.filteredSheetTypeList =  new Array<SheetType>(...this.sheetTypeList);
		Logger.log(LogLevel.Trace, "GameForm.cloneSheetTypeList() - Filtered list cloned to " + this.filteredSheetTypeList.length + " SheetTypes.");
		this.filterSheetTypeList(null, null);
	}

	@Watch("item")
	private filterSheetTypeList(val: Game, oldVal: Game): void {
		if(this.item.gridTypeId == 0) this.item.gridTypeId = undefined;
		if(!this.filteredSheetTypeList || !this.filteredSheetTypeList.length || this.filteredSheetTypeList.length <= 0 || !this.item.gridTypeId) return;
		this.filteredSheetTypeList = this.filteredSheetTypeList.filter((element: SheetType) => element.gridTypeId == this.item.gridTypeId);
		Logger.log(LogLevel.Trace, "GameForm.filterSheetTypeList() - Filtered the list down to " + this.filteredSheetTypeList.length + " SheetTypes.");
	}

	private addExtraBall(): void {
		this.extraBallDrawProbability.push(0);
	}

	public get title(): string {
		let title: string = this.isNew ? "New Game" : "Edit Game";
		return this.localize(title);
	}

//#region Overridable Form Life Cycle Methods: 
	//	loadItem(), itemReactivePropertyExtractor(), saveItem(), validateForm(), itemReactivePropertyInserter(), itemSavedCallback(), listRoute(), closeForm()

	protected get itemReactivePropertyExtractor(): (item: any) => void {
		return ((item: Game) => {
			this.drawInfo =  item.drawInfo;
			this.extraBallDrawProbability =  item.drawInfo.extraBallDrawProbability;
			this.sheetTypeIds =  item.sheetTypeIds;
			this.color =  ColorUtilities.argbToVueColor(item.color);
		});
	}

	protected get validateForm(): () => boolean {
		return (() => {
			//TODO: get all required fields and check their value
			return true;
		});
	}

	protected get itemReactivePropertyInserter(): (item: any) => void {
		return ((item: Game) => {
			item.drawInfo = this.drawInfo;
			item.drawInfo.extraBallDrawProbability = this.extraBallDrawProbability;
			item.sheetTypeIds = this.sheetTypeIds;
			item.color = ColorUtilities.toArgb(this.color.rgba);
		});
	}

//#endregion

	private getSheetTypeName(sheet: SheetType): string {
		let name: string = sheet.name;
		if(!sheet) return this.localize("undefined");
		let type: GridType = this.gridTypeList.find((item: GridType) => item.id == sheet.gridTypeId);
		if(!type) name += " (id: " + sheet.gridTypeId + ")";
		name +=  " / " + this.getGridTypeName(type) + ' - ';
		name += ' ' + this.localize("Price") + ': ' + sheet.price + ',-';
		return name;
	}

	private getGridTypeName(type: GridType): string {
		let name: string = "";
		if(!type) return name;
		if(!type || !type.gridSize) return name;
		let size: GridSize =  type.gridSize;
		if(size) name = this.numberOfBallsEnum[size.numberOfBalls] + " " + " (" + size.rows + " x " + size.columns + ")";
		if(type.freeplayFields && Array.isArray(type.freeplayFields)) name += " " + this.localize("with Freeplay");
		if(type.blanksPerRow && type.blanksPerRow > 0) name += " " + this.localize("with") + " " + type.blanksPerRow + " " +  this.localize("blanks / row");
		return name;
	}

	private pickColor(value: VueColor): void {
		this.item.color = ColorUtilities.toArgb(value.rgba);
		Logger.log(LogLevel.Trace, "GameForm.pickColor() - Picked: " + JSON.stringify(value));
	}

	private prizesChanged(value: Array<Prize>): void {
		this.item.prizes = value;
	}

	public get defaultPurchaseTime() : string {
		return TimeSpan.parse(this.item.defaultPurchaseTime || "00:00:00", "HH:mm:ss").toString("mm:ss");
	}
	public set defaultPurchaseTime(value : string) {
		this.item.defaultPurchaseTime = TimeSpan.parse(value || "00:00", "mm:ss").toString("HH:mm:ss");
	}

	private get normalDrawDelay(): number {
		return parseFloat(
			(this.drawInfo.normalDrawDelay + "")
				.split(":")
				.pop()
		);
	}
	private set normalDrawDelay(value: number) {
		this.drawInfo.normalDrawDelay = "00:00:" + ((value < 10) ? "0" + value: value);
	}

	private get firstDrawDelay(): number {
		return parseFloat(
			(this.drawInfo.firstDrawDelay + "")
				.split(":")
				.pop()
		);
	}
	private set firstDrawDelay(value: number) {
		this.drawInfo.firstDrawDelay = "00:00:" + ((value < 10) ? "0" + value: value);
	}

	private get lastDrawDelay(): number {
		return parseFloat(
			(this.drawInfo.lastDrawDelay + "")
				.split(":")
				.pop()
		);
	}
	private set lastDrawDelay(value: number) {
		this.drawInfo.lastDrawDelay = "00:00:" + ((value < 10) ? "0" + value: value);
	}

	private get extraBallDrawDelay(): number {
		return parseFloat(
			(this.drawInfo.extraBallDrawDelay + "")
				.split(":")
				.pop()
		);
	}
	private set extraBallDrawDelay(value: number) {
		this.drawInfo.extraBallDrawDelay = "00:00:" + ((value < 10) ? "0" + value: value);
	}

}
</script>

<style scoped>

.vc-swatches{
	width:100%;
	height:auto;
}

.gameType {
	padding-top:54px !important;
	padding-left:24px !important;
}

</style>

/**
	* bootstrap-switch - Turn checkboxes and radio buttons into toggle switches.
	*
	* @version v3.3.4
	* @homepage https://bttstrp.github.io/bootstrap-switch
	* @author Mattia Larentis <mattia@larentis.eu> (http://larentis.eu)
	* @license Apache-2.0
	*/

.bootstrap-switch {
	display: inline-block;
	direction: ltr;
	cursor: pointer;
	border-radius: 30px;
	border: 0;
	position: relative;
	text-align: left;
	overflow: hidden;
	margin-bottom: 5px;
	line-height: 8px;
	width: 61px !important;
	height: 26px;
	outline: none;
	z-index: 0;
	margin-right: 1px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.bootstrap-switch .bootstrap-switch-container {
	display: inline-flex;
	top: 0;
	height: 26px;
	border-radius: 4px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	width: 100px !important;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block !important;
	height: 100%;
	color: #fff;
	padding: 6px 12px;
	font-size: 11px;
	text-indent: -5px;
	line-height: 15px;
	-webkit-transition: 0.25s ease-out;
	transition: 0.25s ease-out;

	i{
		font-size: 12px;
		line-height: 14px;
	}
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
	text-align: center;
	z-index: 1;
	float: left;
	width: 50% !important;
	background-color: $info-color;
}


.bootstrap-switch .bootstrap-switch-handle-off{
	background-color: $medium-gray;
}


.bootstrap-switch .bootstrap-switch-label {
	text-align: center;
	z-index: 100;
	color: #333333;
	width: 22px !important;
	height: 22px;
	margin: 2px -11px;
	border-radius: 12px;
	position: relative;
	float: left;
	padding: 0;
	background: #FFFFFF;
	box-shadow: 0 1px 1px #FFFFFF inset, 0 1px 1px rgba(0, 0, 0, 0.25);
}

.bootstrap-switch .bootstrap-switch-handle-on {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}

.bootstrap-switch .bootstrap-switch-handle-off {
	text-indent: 6px;
}

.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
	position: absolute !important;
	top: 0;
	left: 0;
	opacity: 0;
	filter: alpha(opacity=0);
	z-index: -1;
}

.bootstrap-switch.bootstrap-switch-off{
	.bootstrap-switch-handle-on{
		background-color: $medium-gray;
	}
}

.bootstrap-switch.bootstrap-switch-on{
	.bootstrap-switch-handle-off{
		background-color: $info-color;
	}
}

@include bootstrap-switch-color('blue', $color-blue);
@include bootstrap-switch-color('azure', $color-azure);
@include bootstrap-switch-color('orange', $color-orange);
@include bootstrap-switch-color('green', $color-green);
@include bootstrap-switch-color('red', $color-red);
@include bootstrap-switch-color('purple', $color-purple);
@include bootstrap-switch-color('black', $color-black);


.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
	-webkit-transition: margin-left 0.5s;
	transition: margin-left 0.5s;
}


.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
	margin-left: -2px !important;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
	margin-left: -37px !important;
}

.bootstrap-switch.bootstrap-switch-on:hover .bootstrap-switch-label {
	width: 26px !important;
	margin: 2px -15px;
}

.bootstrap-switch.bootstrap-switch-off:hover .bootstrap-switch-label {
	width: 26px !important;
	margin: 2px -15px -13px -11px;
}

// User select
// For selecting text on the page

@mixin user-select($select) {
	-webkit-user-select: $select;
	-moz-user-select: $select;
	-ms-user-select: $select; // IE10+
	user-select: $select;
}

@mixin box-shadow($shadow...) {
	-webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
	box-shadow: $shadow;
}

// Transition w/ opacity

@mixin transition-opacity($time, $type){
	-webkit-transition: opacity $time $type;
	-moz-transition: opacity $time $type;
	-o-transition: opacity $time $type;
	-ms-transition: opacity $time $type;
	transition: opacity $time $type;
}

// Box sizing
@mixin box-sizing($boxmodel) {
	-webkit-box-sizing: $boxmodel;
	-moz-box-sizing: $boxmodel;
	box-sizing: $boxmodel;
}

@mixin transition($time, $type){
	-webkit-transition: all $time $type;
	-moz-transition: all $time $type;
	-o-transition: all $time $type;
	-ms-transition: all $time $type;
	transition: all $time $type;
}

@mixin transform-scale($value){
	-webkit-transform: scale($value);
	-moz-transform: scale($value);
	-o-transform: scale($value);
	-ms-transform: scale($value);
	transform: scale($value);
}

@mixin transform-translate-x($value){
	-webkit-transform:	translate3d($value, 0, 0);
	-moz-transform: translate3d($value, 0, 0);
	-o-transform: translate3d($value, 0, 0);
	-ms-transform: translate3d($value, 0, 0);
	transform: translate3d($value, 0, 0);
}

@mixin transform-translate-3d($value){
	-webkit-transform: translate3d($value, 0, 0);
	-moz-transform: translate3d($value, 0, 0);
	-o-transform: translate3d($value, 0, 0);
	-ms-transform: translate3d($value, 0, 0);
	transform: translate3d($value, 0, 0);
}

@mixin transform-translate-y($value){
	-webkit-transform:	translate3d(0, $value, 0);
	-moz-transform: translate3d(0, $value, 0);
	-o-transform: translate3d(0, $value, 0);
	-ms-transform: translate3d(0, $value, 0);
	transform: translate3d(0, $value, 0);
}

@mixin transform-translate-y-dropdown($value){
	-webkit-transform:	translate3d(0, $value, 0) !important;
	-moz-transform: translate3d(0, $value, 0) !important;
	-o-transform: translate3d(0, $value, 0) !important;
	-ms-transform: translate3d(0, $value, 0) !important;
	transform: translate3d(0, $value, 0) !important;
}

@mixin transform-origin($coordinates){
	-webkit-transform-origin: $coordinates;
	-moz-transform-origin: $coordinates;
	-o-transform-origin: $coordinates;
	-ms-transform-origin: $coordinates;
	transform-origin: $coordinates;
}

@mixin icon-gradient ($top-color, $bottom-color){
	background: $top-color;
	background: -moz-linear-gradient(top,	$top-color 0%, $bottom-color 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top-color), color-stop(100%,$bottom-color));
	background: -webkit-linear-gradient(top,	$top-color 0%,$bottom-color 100%);
	background: -o-linear-gradient(top,	$top-color 0%,$bottom-color 100%);
	background: -ms-linear-gradient(top,	$top-color 0%,$bottom-color 100%);
	background: linear-gradient(to bottom,	$top-color 0%,$bottom-color 100%);
	background-size: 150% 150%;
}

@mixin radial-gradient($extern-color, $center-color){
	background: $extern-color;
	background: -moz-radial-gradient(center, ellipse cover, $center-color 0%, $extern-color 100%); /* FF3.6+ */
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$center-color), color-stop(100%,$extern-color)); /* Chrome,Safari4+ */
	background: -webkit-radial-gradient(center, ellipse cover, $center-color 0%,$extern-color 100%); /* Chrome10+,Safari5.1+ */
	background: -o-radial-gradient(center, ellipse cover, $center-color 0%,$extern-color 100%); /* Opera 12+ */
	background: -ms-radial-gradient(center, ellipse cover, $center-color 0%,$extern-color 100%); /* IE10+ */
	background: radial-gradient(ellipse at center, $center-color 0%,$extern-color 100%); /* W3C */
	background-size: 550% 450%;
}

@mixin vertical-align {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin rotate-180(){
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

@mixin bar-animation($type){
	-webkit-animation: $type 500ms linear 0s;
	-moz-animation: $type 500ms linear 0s;
	animation: $type 500ms 0s;
	-webkit-animation-fill-mode: forwards;
	-moz-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

@mixin topbar-x-rotation(){
	@keyframes topbar-x {
		0% {top: 0px; transform: rotate(0deg); }
		45% {top: 6px; transform: rotate(145deg); }
		75% {transform: rotate(130deg); }
		100% {transform: rotate(135deg); }
	}
	@-webkit-keyframes topbar-x {
		0% {top: 0px; -webkit-transform: rotate(0deg); }
		45% {top: 6px; -webkit-transform: rotate(145deg); }
		75% {-webkit-transform: rotate(130deg); }
		100% { -webkit-transform: rotate(135deg); }
	}
	@-moz-keyframes topbar-x {
		0% {top: 0px; -moz-transform: rotate(0deg); }
		45% {top: 6px; -moz-transform: rotate(145deg); }
		75% {-moz-transform: rotate(130deg); }
		100% { -moz-transform: rotate(135deg); }
	}
}

@mixin topbar-back-rotation(){
	@keyframes topbar-back {
		0% { top: 6px; transform: rotate(135deg); }
		45% { transform: rotate(-10deg); }
		75% { transform: rotate(5deg); }
		100% { top: 0px; transform: rotate(0); }
	}

	@-webkit-keyframes topbar-back {
		0% { top: 6px; -webkit-transform: rotate(135deg); }
		45% { -webkit-transform: rotate(-10deg); }
		75% { -webkit-transform: rotate(5deg); }
		100% { top: 0px; -webkit-transform: rotate(0); }
	}

	@-moz-keyframes topbar-back {
		0% { top: 6px; -moz-transform: rotate(135deg); }
		45% { -moz-transform: rotate(-10deg); }
		75% { -moz-transform: rotate(5deg); }
		100% { top: 0px; -moz-transform: rotate(0); }
	}
}

@mixin bottombar-x-rotation(){
	@keyframes bottombar-x {
		0% {bottom: 0px; transform: rotate(0deg);}
		45% {bottom: 6px; transform: rotate(-145deg);}
		75% {transform: rotate(-130deg);}
		100% {transform: rotate(-135deg);}
	}
	@-webkit-keyframes bottombar-x {
		0% {bottom: 0px; -webkit-transform: rotate(0deg);}
		45% {bottom: 6px; -webkit-transform: rotate(-145deg);}
		75% {-webkit-transform: rotate(-130deg);}
		100% {-webkit-transform: rotate(-135deg);}
	}
	@-moz-keyframes bottombar-x {
		0% {bottom: 0px; -moz-transform: rotate(0deg);}
		45% {bottom: 6px; -moz-transform: rotate(-145deg);}
		75% {-moz-transform: rotate(-130deg);}
		100% {-moz-transform: rotate(-135deg);}
	}
}

@mixin bottombar-back-rotation{
	@keyframes bottombar-back {
		0% { bottom: 6px;transform: rotate(-135deg);}
		45% { transform: rotate(10deg);}
		75% { transform: rotate(-5deg);}
		100% { bottom: 0px;transform: rotate(0);}
	}
	@-webkit-keyframes bottombar-back {
		0% {bottom: 6px;-webkit-transform: rotate(-135deg);}
		45% {-webkit-transform: rotate(10deg);}
		75% {-webkit-transform: rotate(-5deg);}
		100% {bottom: 0px;-webkit-transform: rotate(0);}
	}
	@-moz-keyframes bottombar-back {
		0% {bottom: 6px;-moz-transform: rotate(-135deg);}
		45% {-moz-transform: rotate(10deg);}
		75% {-moz-transform: rotate(-5deg);}
		100% {bottom: 0px;-moz-transform: rotate(0);}
	}

}

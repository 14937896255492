<template>
	<auth-layout pageClass="login-page">
		<div class="row slider d-flex justify-content-center align-items-center">
			<div class="col-lg-4 col-md-6 col-sm-8">
				<form autocomplete="on">
					<fade-render-transition>
						<card :title="localize('Login')">
							<div>
								<b-form-group>
									<label>{{ localize('Email address') }}</label>
									<b-input
										type="email"
										:placeholder="localize('email address')"
										v-model="model.email"
										aria-autocomplete="email"
										autocomplete="email"
									></b-input>
								</b-form-group>
								<b-form-group>
									<label>{{ localize('Passsword') }}</label>
									<b-input
										type="password"
										:placeholder="localize('Password')"
										v-model="model.password"
										aria-autocomplete="current-password"
										autocomplete="current-password"
									></b-input>
								</b-form-group>
							</div>
							<div class="text-center">
								<button type="button" @click="logIn(model.email, model.password)" class="btn btn-fill btn-info btn-round btn-wd">{{ localize("Log in") }}</button>
								<!-- <br>
								<div class="forgot">
									<router-link to="/register" class="card-category">{{ localize("Forgot your password?") }}</router-link>
								</div> -->
							</div>
						</card>
					</fade-render-transition>
				</form>
			</div>
		</div>
	</auth-layout>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent.vue';
import AuthLayout from '@/components/layout/AuthLayout.vue';
import { Logger, Authenticator } from '@/utilities';
import { LogLevel, Login } from '@/models';
import { Checkbox as LCheckbox, FadeRenderTransition } from '@/components/lbd/index';
import Card from '@/components/lbd/Cards/Card.vue';


@Component({
	components: {
		Card,
		LCheckbox,
		FadeRenderTransition,
		AuthLayout
	}
})
export default class LoginComponent extends BaseComponent {
	private model: Login = new Login();

	public beforeDestroy() {
		this.closeMenu();
	}

	private logIn(email: string, password: string) {
		Authenticator.logIn(email, password);
	}

	private toggleNavbar() {
		document.body.classList.toggle("nav-open");
	}

	private closeMenu() {
		document.body.classList.remove("nav-open");
		document.body.classList.remove("off-canvas-sidebar");
	}

}
</script>

<style>
.slider{
	height:calc(100vh - 120px);
}

.navbar-nav .nav-item p{
	line-height: inherit;
	margin-left: 5px;
}
</style>

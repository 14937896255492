<template>
	<div>
		<div class="wrapper wrapper-full-page full-page">
			<!--	 you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple | black" -->
			<div class="content">
				<div class="logo">
					<router-link to="/" tag="div" class="logo-mini">
						<div class="logo-img">
							<img :src="logo" alt="Logo">
						</div>
						<div>{{ localize("Admin - bingo.no") }}</div>
					</router-link>
				</div>
				<slot></slot>
			</div>
			<footer class="footer footer-transparent">
				<div class="container d-flex flex-lg-row flex-column justify-content-lg-between justify-content-center align-items-center">
					<nav>
						<ul>
							<li>
								<a href="https://egs-as.no">Electronic Gaming Systems AS</a>
							</li>
						</ul>
					</nav>
					<div class="copyright">&copy; 2019 Vexta</div>
				</div>
			</footer>

			<div class="full-page-background"></div>
		</div>
		<div class="collapse navbar-collapse off-canvas-sidebar">
			<ul class="nav nav-mobile-menu">
				<!-- <router-link to="/register" tag="li">
					<a>{{ localize("Register") }}</a>
				</router-link> -->
				<router-link to="/" tag="li">
					<a>{{ localize("Dashboard") }}</a>
				</router-link>
			</ul>
		</div>
	</div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent.vue';
import CollapseTransition from '@/components/lbd/Transitions/collapse-transition';

let image = require('@/assets/img/icon.svg');

@Component({
	components: {
		[CollapseTransition.name]: CollapseTransition
	}
})
export default class AuthLayout extends BaseComponent {
	public showMenu: boolean = false;

	@Prop({ type: String, default: "login-page" })
	public pageClass: string;

	get logo() {
		return image;
	}

}
</script>

<style scoped>

.full-page-background{
	background: rgba(203, 203, 210, 0.15);
}

.full-page:before {
    opacity: 9 !important;
    background: none ! important;
}
.full-page:after {
    background: none ! important;
}

.navbar-nav .nav-item p {
	line-height: inherit;
	margin-left: 5px;
}

.navbar-wrapper{
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
}

.wrapper-full-page .navbar-toggler,
.wrapper-full-page .navbar-collapse .navbar-nav{
	margin-right: 30px;
}

.navbar-collapse .navbar-nav .nav-link {
	width: 100%;
	display: flex;
	justify-content: center;
}

.logo-mini,
.logo-mini div,
.logo-img{
	display: inline-block;
}

.logo-mini{
	position: relative;
	margin: 12px;
	line-height: 34px;
	font-size: 1.3em;
	text-align: center;
	cursor: pointer;
}

.logo-img {
    width: 34px;
    display: inline-block;
    height: 34px;
    margin-left: 0;
	margin-right: 10px;
    background: #fff;
    border-radius: 40px;
    text-align: center;
    line-height: 34px;
    border: 1px solid #ccc !important;
	opacity: 1;
}

.logo-mini img{
	position: relative;
	top:-4px;
}

.full-page .footer nav > ul a:not(.btn),
.full-page .footer,
.full-page .footer .copyright a,
.copyright{
	color:#000 !important;
}

</style>

.alert, .vue-notifyjs.alert{
		border: 0;
		border-radius: 0;
		color: #FFFFFF;
		padding: 10px 15px;
		font-size: 14px;

		.container &{
				border-radius: 4px;

		}
		.navbar &{
				border-radius: 0;
				left: 0;
				position: absolute;
				right: 0;
				top: 85px;
				width: 100%;
				z-index: 3;
		}
		.navbar:not(.navbar-transparent) &{
				top: 70px;
		}

		span[data-notify="icon"]{
				font-size: 30px;
				display: block;
				left: 15px;
				position: absolute;
				top: 50%;
				margin-top: -15px;
		}

		i.nc-simple-remove{
				font-size: 12px !important;
				font: bold normal normal 14px/1 'nucleo-icons';
		}

		button.close{
				position: absolute;
				right: 10px;
				top: 50%;
				margin-top: -13px;
				z-index: 3;
				background-color: #FFFFFF;
				display: block;
				border-radius: 50%;
				opacity: .4;
				line-height: 9px;
				width: 25px;
				height: 25px;
				outline: 0 !important;
				text-align: center;
				padding: 3px;
				font-weight: 300;

				&:hover{
						opacity: .55;
				}
		}

		.close ~ span{
				display: block;
				max-width: 89%;
		}

		&[data-notify="container"]{
				padding: 10px 10px 10px 20px;
				border-radius: $border-radius-base;
		}

		&.alert-with-icon{
				padding-left: 65px;
		}
}
.alert,
.vue-notifyjs.alert{
	&.alert-primary{
		background-color: $blue-navbar;
		color: white;
	}
	&.alert-info{
		background-color: $azure-navbar;
		 color: white;
	}
	&.alert-success {
		background-color: $green-navbar;
		 color: white;
	}
	&.alert-warning {
		background-color: $orange-navbar;
		 color: white;
	}
	&.alert-danger {
		background-color: $red-navbar;
		 color: white;
	}
}


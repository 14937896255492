<template>
	<card :title="localize('Games')">
		<a
			class="btn btn-add float-right"
			@click.prevent="addItem()"
			:title="localize('Add new Item') + ' (' + localize('Shortcut') + ': Ctrl + )'"
		>
			<icon :icon="['far', 'plus']" fixed-width size="lg" />
		</a>
		<b-table
			:items="gameList"
			:fields="fields"
			show-empty
			striped
			:empty-text="emptyResultText"
			:empty-filtered-text="localize('There are no items matching your request')"
		>
			<template v-slot:cell(sheetTypeIds)="row">
				<ul v-if="row.item.sheetTypeIds && row.item.sheetTypeIds.length && row.item.sheetTypeIds.length > 0">
					<li
						v-for="(sheet, index) in getSortedSheetList(row.item.sheetTypeIds)"
						:key="index"
					>
						<div class="multiplier">{{ sheet.gridsPerSheet }}x </div>
						<table
							class="pattern"
							:style="{
								'backgroundColor': getColorCss(sheet)
							}"
						>
							<tr
								v-for="rowNumber in getNumberOfRows(sheet)"
								:key="rowNumber.index"
							>
								<td
									v-for="columnNumber in getNumberOfColumns(sheet)"
									:key="columnNumber.index"
								>
								</td>
							</tr>
						</table>
						<!-- <div class="price">{{ localize("at") }} {{ sheetTypeList.find((element) => element.id == sheet).price }},- </div> -->
					</li>
				</ul>
			</template>
			<template v-slot:cell(color)="row">
				<div
					class="colorPatch"
					:style="{
						background: getGameColor(row.value)
					}"
				> </div>
			</template>
			<template v-slot:cell(showLetters)="row">
				<icon v-if="row.item.drawInfo.showLetters" :icon="['far', 'check-square']" fixed-width size="lg" />
			</template>
			<template v-slot:cell(actions)="data">
				<ul class="list-inline mb-0">
					<li class="list-inline-item">
						<router-link tag="button" :to="{ name: 'Update ' + itemType, params: { id: data.item.id }}" class="btn-action" :title="localize('Edit item')" >
							<icon class="text-info" :icon="['far', 'edit']" fixed-width size="lg" />
						</router-link>
					</li>
					<li class="list-inline-item">
						<b-button @click.prevent="deleteItem(data.index, data.item)" class="btn-action" :title="localize('Delete item')">
							<icon class="text-danger" :icon="['far', 'trash-alt']" fixed-width size="lg" />
						</b-button>
					</li>
				</ul>
			</template>
		</b-table>
	</card>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError, ColorUtilities, TimeSpan } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { IGame as Game, IPrizePatternGroup as PrizePatternGroup, ISheetType as SheetType, IGridType as GridType, IGridSize as GridSize } from '@/services/api';

let tinycolor = require("tinycolor2");


@Component({
	name: 'GameList',
	components: {
		Card
	}
})
export default class GameList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: true
		},
		{
			key: "color",
			label: this.localize("Color"),
			sortable: false
		},
		{
			key: "type",
			label: this.localize("Type"),
			formatter: this.typeFormatter,
			sortable: false
		},
		{
			key: "sheetTypeIds",
			label: this.localize("Sheets"),
			sortable: false
		},
		//TODO: list prizes here??
		// {
		// 	key: "prizeIds",
		// 	label: this.localize("Prizes"),
		// 	formatter: this.prizeIdsFormatter,
		// 	sortable: false
		// },
		{
			key: "showLetters",
			label: this.localize("Show Letters"),
			sortable: false
		},
		{
			key: "drawDelay",
			label: this.localize("Draw Delay"),
			formatter: this.drawDelayFormatter,
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	// private prizeIdsFormatter(value: Array<number>, key: string, item: Game): string {
	// 	return this.prizeList.filter((item: Prize) => value.includes(item.id)).map((item: Prize): string => item.name).join(', ');
	// }

	private drawDelayFormatter(value: string, key: string, item: Game): string {
		let dur: TimeSpan = new TimeSpan();
		if(item && item.drawInfo && item.drawInfo.normalDrawDelay) dur = TimeSpan.parse(item.drawInfo.normalDrawDelay, "HH:MM:SS.ff");
		return dur.toString("S.f");
	}

	private typeFormatter(value: any, key: string, item: Game): string {
		let sheet: SheetType = this.sheetTypeList.find((elemenmt: SheetType) => elemenmt.id == item.sheetTypeIds[0]);
		if(!sheet) return "";
		let type: GridType = this.gridTypeList.find((element: GridType) => element.id == sheet.gridTypeId);
		if(!type || !type.gridSize) return "";
		return this.numberOfBallsEnum[type.gridSize.numberOfBalls]
	}

	private sheetTypeName(value: number): string {
		let type: SheetType = this.sheetTypeList.find((item: SheetType) => item.id == value);
		if(!type) return "";
		return type.gridsPerSheet + ' ' + this.localize(type.gridsPerSheet > 1 ? 'grids': 'grid') + ' ' + '(' + this.getGridTypeName(type.gridTypeId) + ')'+ ' ' + this.ticketColorEnum[type.color] + ' @ ' + type.price + ',-';
	}

	private getSortedSheetList(value: Array<number>) : Array<SheetType> {
		 let list: Array<SheetType> = this.sheetTypeList
			.filter(
				(item: SheetType) => 
				value.includes(item.id)
			)
			.sort(
				(a: SheetType, b: SheetType) =>  
				a.gridsPerSheet < b.gridsPerSheet ? -1 : 1
			);
		return list;
	}

	private getNumberOfRows(sheet: SheetType) : number {
		let type: GridType = this.gridTypeList.find((item: GridType) => item.id == sheet.gridTypeId);
		if(!type || !type.gridSize) return 0;
		return type.gridSize.rows;
	}

	private getNumberOfColumns(sheet: SheetType) : number {
		let type: GridType = this.gridTypeList.find((item: GridType) => item.id == sheet.gridTypeId);
		if(!type || !type.gridSize) return 0;
		return type.gridSize.columns;
	}

	private getColorCss(sheet: SheetType): string {
		return ColorUtilities.colorNumber2cssRgbaString(sheet.color);
	}

	public getGridTypeName(id: number): string {
		let type: GridType = this.gridTypeList.find((item: GridType) => item.id == id);
		if(!type || !type.gridSize) return id + "";
		return type.gridSize.rows + " x " + type.gridSize.columns;
	}

	private getGameColor(value: number): string {
		let tColor = tinycolor(ColorUtilities.argbToVueColor(value).rgba);
		return tColor.toHexString();
	}

	private formatTime(timeSpan: string): string {
		return timeSpan.substring(0, 5);
	}

}
</script>

<style scoped>

ul{
	list-style: none;
	margin:0;
	padding:0;
}

li {
	display: inline-block;
	position: relative;
	margin-right:3px;
}

div.multiplier {
	display: block;
	position: absolute;
	padding-left:3px;
	font-size: 1.3em;
	font-weight: bold;
	color:#fff;
   -webkit-text-stroke-width: 1px;
   -webkit-text-stroke-color: black;	
}

div.price {
	display: inline-block;
	position: relative;
	top:-8px;
	left:5px;
	font-size: 1.1em;
}

table.pattern{
	display: inline-block;
	margin:0;
	padding:0;
}

table.pattern td{
	display: table-cell !important;
	width:5px;
	height:5px;
	margin:0;
	padding:0 !important;
	border: 1px solid rgba(0, 0, 0, 0.25);
}

.colorPatch{
	width:100%;
	height:100%;
	min-height:20px;
}

td[aria-colindex="5"] {
	text-align: center;
	background: #f44;
}
</style>

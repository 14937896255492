











































































































































































.duration{
	// This selector does not work!!! Why???
	padding-left:15px;
}

.card .card{
	margin-top: 0 !important;
}

h2{
	position:absolute;
	top:25px;
	right:15px;
	margin:0;
	padding:0;
	font-size: 12px;
}

.vc-swatches{
	width:100%;
	height:auto;
}


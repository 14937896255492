<template>
	<card :title="localize('Game Blocks')">
		<a
			class="btn btn-add float-right"
			@click.prevent="addItem()"
			:title="localize('Add new Item') + ' (' + localize('Shortcut') + ': Ctrl + )'"
		>
			<icon :icon="['far', 'plus']" fixed-width size="lg" />
		</a>
		<b-table
			:items="gameBlockList"
			:fields="fields"
			:sort-by="'id'"
			:sort-desc="false"
			show-empty
			striped
			:empty-text="emptyResultText"
			:label-sort-asc="localize('Click to sort Ascending')"
			:label-sort-desc="localize('Click to sort Descending')"
			:empty-filtered-text="localize('There are no items matching your request')"
		>
			<template v-slot:cell(color)="row">
				<div
					class="colorPatch"
					:style="{
						background: getBlockColor(row.value)
					}"
				> </div>
			</template>
			<template v-slot:cell(gameIds)="row">
				<span
					:title="row.item.gameIds.join(',')"
				>{{ gameIdsFormatter(row.item.gameIds, "gameIds", row.item) }}</span>
			</template>
			<template v-slot:cell(actions)="row">
				<a
					v-b-tooltip.hover.top="localize('Edit')"
					class="btn-info btn-link"
					@click.stop="editItem(row.item.id)"
				>
					<icon :icon="['far', 'edit']" fixed-width size="lg" />
				</a>
				<a
					v-b-tooltip.hover.top="localize('Delete')"
					class="btn-danger btn-link"
					@click.stop="deleteItem(row.index, row.item.id)"
				>
					<icon :icon="['far', 'trash-alt']" fixed-width size="lg" />
				</a>
			</template>
		</b-table>
	</card>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError, ColorUtilities, TimeSpan } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { IGamePlan as GamePlan, IGame as Game, GameBlock, BlockId } from '@/services/api';

let tinycolor = require("tinycolor2");


@Component({
	name: 'GameBlockList',
	components: {
		Card
	}
})
export default class GameBlockList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			formatter: this.idFormatter,
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name")
		},
		{
			key: "color",
			label: this.localize("Color")
		},
		{
			key: "duration",
			label: this.localize("Duration"),
			formatter: this.durationFormatter
		},
		{
			key: "gameIds",
			label: this.localize("Games"),
			formatter: this.gameIdsFormatter
		},
		{
			key: "actions",
			label: this.localize("Actions")
		}
	];

	private idFormatter(value: BlockId, key: string, item: GameBlock): string {
		return value.id + "";
	}

	private durationFormatter(value: string, key: string, item: GameBlock): string {
		let dur: TimeSpan = new TimeSpan();
		if(item && item.duration) dur = TimeSpan.parse(item.duration);
		return dur.toString("HH:MM:SS");
	}

	private gameIdsFormatter(value: Array<number>, key: string, item: GameBlock): string {
		if(!value || !Array.isArray(value)) return this.localize("undefined");
		let name: string = (this.gameList || new Array<Game>())
			.filter((element: Game) => value.includes(element.id))
			.map((element: Game): string => element.name)
			.join(', ');
		if(!name) name = value.join(', ');
		return name;
	}

	private getBlockColor(value: number): string {
		let tColor = tinycolor(ColorUtilities.argbToVueColor(value).rgba);
		return tColor.toHexString();
	}

}
</script>

<style scoped>

.colorPatch{
	width:100%;
	height:100%;
	min-height:20px;
}

</style>






















































































































































nav {
	display: block;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 52px;
	padding-bottom: 100px;
	z-index: 1;
	max-height: calc(100vh - 75px);
	min-height: 100%;
	overflow: auto;
	background: #000;
	opacity: .85;
}

ul{
	list-style-type: none;
}

div {
	position: relative;
	display: block;
	width:100%;
	padding: 9px 0px 10px 9px;
	margin: 0;
	z-index: 4;

	// &:before{
	// 	content: "";
	// 	position: absolute;
	// 	bottom: 0px;
	// 	right: 15px;
	// 	width: calc(100% - 30px);
	// 	height: 1px;
	// 	background-color: rgba(#fff, 0.3);
	// }
}

@media (min-width: 992px) {
	nav {
		width: 260px;
	}

.navbar-search-form-mobile,
	.nav-mobile-menu {
		display: none;
	}
}

.minimize {
	padding: 36px 0px 9px;
	margin: 0;
	position: relative;
	z-index: 4;
	display: block;
}

.minimize:before{
	background-color: rgba(255, 255, 255, 0.3);
	content: "";
	position: absolute;
	top: 20px;
	right: 10px;
	width: calc(100% - 10px);
	height: 1px;
}

button,
.btn{
	display:inline-block !important;
	position: relative !important;
	margin-left: 23px !important;
	margin-right: 15px !important;
}

.btn.btn-outline {
	color: #fff !important;
	background-color: transparent;
	border: 1px solid #fff !important;
	opacity: 1;
	filter: alpha(opacity=100);
}

.btn:hover{
	background: #fff !important;
}
.btn:hover.btn-outline{
	color: #424242 !important;
	border: 1px solid #424242 !important;
}
.btn:hover .fa-ellipsis-v{
	color: #424242 !important;
}
.btn:hover .fa-navicon{
	color: #424242 !important;
}

button>i.fa{
	margin-left:0;
	margin-right:0;
}


.fa-ellipsis-v{
	color:#fff;
}

.fa-navicon{
	color:#fff;
}


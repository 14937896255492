<template>
	<b-row class="extra-rules">
		<b-col>
			<b-form-group :label="localize('Type')">
				<el-select
					class="form-group select-default"
					size="small"
					:placeholder="localize('Choose')"
					v-model="item.type"
					:no-data-text="emptyResultText"
					:loading-text="localize('loading...')"
					required
					aria-required
					:invalid="!item || item.type == undefined || item.type == null"
					@change="ruleChanged"
				>
					<el-option
						class="select-default"
						role="option"
						v-for="(type, index) in extraRuleTypeEnum"
						v-if="!isNaN(Number(type))"
						:key="index"
						:aria-label="localize('Extra Rule Type') + ': ' + localize(extraRuleTypeEnum[type])"
						:aria-selected="item.type == type"
						:value="type"
						:label="localize(extraRuleTypeEnum[type])"
					></el-option>
				</el-select>
			</b-form-group>
		</b-col>
		<b-col>
			<b-form-group
				:label="localize('Value')"
				v-if="item.type != extraRuleTypeEnum.None"
			>
				<el-select
					v-if="item.type == extraRuleTypeEnum.Column"
					class="form-group select-default select-value"
					size="small"
					:placeholder="localize('Choose')"
					v-model="item.value"
					:no-data-text="emptyResultText"
					:loading-text="localize('loading...')"
					required
					aria-required
					:invalid="item.value === undefined || item.value < 0 || item.value > 5"
					@change="ruleChanged"
				>
					<el-option
						class="select-default"
						role="option"
						v-for="col in getNumberOfColumns()"
						:key="col"
						:aria-label="localize('Column') + ': ' + getColumnName(col)"
						:aria-selected="item.value == (col - 1)"
						:value="col - 1"
						:label="getColumnName(col)"
					></el-option>
				</el-select>
				<el-select
					v-if="item.type == extraRuleTypeEnum.Row"
					class="form-group select-default select-value"
					size="small"
					:placeholder="localize('Choose')"
					v-model="item.value"
					:no-data-text="emptyResultText"
					:loading-text="localize('loading...')"
					required
					aria-required
					:invalid="!(item.value > 0)"
					@change="ruleChanged"
				>
					<el-option
						class="select-default"
						role="option"
						v-for="ro in getNumberOfRows()"
						:key="ro"
						:aria-label="localize('Row') + ': ' + ro"
						:aria-selected="item.value == ro"
						:value="ro"
						:label="ro"
					></el-option>
				</el-select>
				<input
					v-if="
						item.type == extraRuleTypeEnum.DividebleBy ||
						item.type == extraRuleTypeEnum.NotDividebleBy ||
						item.type == extraRuleTypeEnum.ExactNumber ||
						item.type == extraRuleTypeEnum.PrizeMultiplicator ||
						item.type == extraRuleTypeEnum.ExactDraw ||
						item.type == extraRuleTypeEnum.WithinNumber
					"
					v-model="item.value"
					type="number"
					:max="getMaxNumber(item.type)"
					class="form-control"
					required
					aria-required
					@change="ruleChanged"
				/>
			</b-form-group>
		</b-col>
		<b-col>
			<b-form-group
				:label="localize('Pot')"
				v-if="item.type != extraRuleTypeEnum.None"
			>
				<el-select
					class="form-group select-default"
					size="small"
					:placeholder="localize('Choose')"
					v-model="item.potId"
					:no-data-text="emptyResultText"
					:loading-text="localize('loading...')"
					required
					aria-required
					:invalid="!(item.potId > 0)"
					@change="ruleChanged"
				>
					<el-option
						class="select-default"
						role="option"
						:value="0"
						:label="localize('No pot')"
					></el-option>				
					<el-option
						class="select-default"
						role="option"
						v-for="(pot, index) in potList"
						:key="index"
						:value="pot.id"
						:label="pot.name + ' - ' + pot.buildPercent + '% ' + localize('build')"
					></el-option>
				</el-select>
			</b-form-group>
		</b-col>
		<b-col>
			<b-form-group
				v-if="item.type != extraRuleTypeEnum.None && item.potId != 0"
				:label="localize('Prize %')"
			>
				<span class="nobr">
					<input
						v-model="item.potPayoutPercent"
						type="number"
						placeholder="number %"
						:max="100"
						:min="1"
						aria-valuemin="1"
						aria-valuemax="100"
						size="small"
						class="form-control percentage"
						required
						aria-required
						@change="ruleChanged"
					/> % {{ localize("of pot") }}
				</span>
			</b-form-group>
			<b-form-group
				v-if="item.type != extraRuleTypeEnum.None && item.potId != 0"
				:label="localize('Minimum Prize')"
			>
				<span class="nobr">
					<input
						v-model="item.minimumPotPayout"
						type="number"
						placeholder="<number>"
						:max="100000"
						:min="0"
						aria-valuemin="1"
						aria-valuemax="100"
						size="small"
						class="form-control"
						required
						aria-required
						@change="ruleChanged"
					/>{{ localize("NOK") }}
				</span>
			</b-form-group>
			<b-form-group
				v-if="item.type != extraRuleTypeEnum.None && item.potId == 0"
				:label="localize('Prize value')"
			>
				<span class="nobr">
					<input
						v-model="item.fixedPayoutValue"
						type="number"
						placeholder="<number>"
						:max="10000"
						:min="1"
						aria-valuemin="1"
						aria-valuemax="100"
						size="small"
						class="form-control"
						required
						aria-required
						@change="ruleChanged"
					/>{{ localize("NOK") }}
				</span>
			</b-form-group>
		</b-col>
	</b-row>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { Select, Option } from 'element-ui';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import { Prize, IGridType as GridType, ExtraRuleType, ExtraRule, Pot, PayoutSource, Game } from '@/services/api';


@Component({
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option
	}
})
export default class ExtraRuleForm extends BaseComponent {
	private extraRuleTypeEnum: typeof ExtraRuleType = ExtraRuleType;
	private item: ExtraRule = new ExtraRule({
		type: ExtraRuleType.None,
		value: undefined,
		potId: undefined,
		potPayoutPercent: 0,
		minimumPotPayout:0,
		fixedPayoutValue: 0,
		fixedPayoutSource: PayoutSource.Pot
	});

	public created() {
		this.item = this.prize.extraRule;
	}

	@Prop({ type: Object, required: true })
	public prize: Prize;

	@Prop({ type: Number, required: true })
	public gridTypeId: number;

	@Watch("prize")
	private prizeChanged(value: Prize, oldVal: Prize): void {
		if(!value) {
			this.item = new ExtraRule({
				type: ExtraRuleType.None,
				value: undefined,
				potId: undefined,
				potPayoutPercent: 0,
				minimumPotPayout:0,
				fixedPayoutValue: 0,
				fixedPayoutSource: PayoutSource.Pot
			});
			return;
		}
		this.item = this.prize.extraRule;
	}

	private ruleChanged(): void {
		this.item.fixedPayoutSource = (this.item.potId == 0) ? PayoutSource.None: PayoutSource.Pot;
		this.$emit("change", this.item);
	}

	private getNumberOfColumns(): number {
		let type = (this.gridTypeList || new Array<GridType>())
			.find((element: GridType): boolean => element.id == this.gridTypeId);
		if(!type || !type.gridSize) return 0;
		return type.gridSize.columns;
	}

	private getNumberOfRows(): number {
		let type = (this.gridTypeList || new Array<GridType>())
			.find((element: GridType): boolean => element.id == this.gridTypeId);
		if(!type || !type.gridSize) return 0;
		return type.gridSize.rows;
	}

	private getColumnName(value: number): string {
		if(this.getNumberOfColumns() == 5) return ["B","I","N","G","O"][value - 1];
		return value + "";
	}

	private getMaxNumber(value: ExtraRuleType): number | undefined {
		if(value != ExtraRuleType.ExactDraw && value != ExtraRuleType.ExactNumber) return undefined;
		let type = (this.gridTypeList || new Array<GridType>())
			.find((element: GridType): boolean => element.id == this.gridTypeId);
		if(type && type.gridSize) return type.gridSize.numberOfBalls;
		return undefined;
	}

	private get gridTypeList(): Array<GridType> {
		return this.$store.state.cacheStore.gridTypeList;
	}

	private get potList(): Array<Pot> {
		return this.$store.state.cacheStore.potList;
	}

	private get emptyResultText(): string {
		return this.localize('There are no items to show');
	}

}

</script>

<style scoped>

.form-group {
	margin-bottom: 0;
}

.form-control {
	display:inline-block !important;
	padding:0 12px !important;
	width: 56px;
	height: 32px;
}

table.extra-rules {
	margin:0;
}

table.extra-rules > thead > tr > th,
table.extra-rules th{
	padding-top:0 !important;
	padding-bottom:0 !important;
}

.select-value{
	width:60px;
}

input{
	min-width: fit-content;
}

.nobr{
	white-space: nowrap;
}

.danger{
	color:#FB404B;
}

</style>

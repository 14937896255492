





































































































































h2{
	margin-top: 10px;
	padding-left:calc(30px + 1.25em);
	font-size: 1em;
}

img {
    max-width: 26px;
}

ul {
	list-style-type: none;
	clear:both;
	margin:15px 0 0 0;
	padding:0;
	font-weight: 200;
	list-style-type: none;
}

ul ul{
	margin:0;
}

li a {
	display:block;
	//margin: 0px 5px 5px 5px;
	padding: 5px 5px;
	line-height: 30px;
	font-size: 0.80em;
	font-weight: 600;
	text-transform: uppercase;
	white-space: nowrap;
	opacity: .86;
	border-radius: 4px;
}

ul ul li{
	padding-left: 20px;
}

li a.active {
    opacity: 1;
    background: rgba(255, 255, 255, 0.23);
}

a[href] {
	color: #fff;
}

li a:hover {
    opacity: 1;
    background: rgba(255, 255, 255, 0.08);
}

.svg-inline--fa {
	vertical-align:middle;
}

a span {
	//display: none;
	margin: 0 0 0 12px;
}

hr {
	position:relative;
	left:-5px;
	width:calc(100% - 10px);
	margin-top: 0.9rem;
	margin-bottom: 0.9rem;	
	border-top: 1px solid rgba(255, 255, 255, 0.08)
}

@media only screen and (min-width: 1000px)  {

	div {
		padding: 12px 0px 15px 23px;
	}

	ul li a {
		//margin: 0px 15px 5px 10px;
		//padding: 10px 15px;
	}

	span {
		display: inline-block;
	}
}


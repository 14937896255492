<template>
	<div
		class="form-group"
		:class="[
			{'input-group': hasIcon},
			{'has-error': error},
			{'is-error el-form-item': error}
		]"
	>
		<slot name="label">
			<label v-if="label" class="control-label" :class="labelClasses">{{ label }}</label>
		</slot>
		<slot name="addonLeft">
			<span v-if="addonLeftIcon" class="input-group-addon">
				<i :class="addonLeftIcon"></i>
			</span>
		</slot>
		<slot>
			<input
				:value="value"
				@input="$emit('input',$event.target.value)"
				v-bind="$attrs"
				class="form-control"
				:class="inputClasses"
				aria-describedby="addon-right addon-left"
			>
		</slot>
		<slot name="help">
			<a class="help" v-if="help" v-b-tooltip.hover.top="help">H</a>
		</slot>
		<slot name="helpBlock">
			<small class="text-danger" v-if="error">{{ error }}</small>
		</slot>
		<slot name="infoBlock"></slot>
		<slot name="addonRight">
			<span v-if="addonRightIcon" class="input-group-addon">
				<i :class="addonRightIcon"></i>
			</span>
		</slot>
	</div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
	name: "fg-input"
})
export default class FormGroupInput extends Vue {
	inheritAttrs: boolean = false;

	@Prop(String)
	label: string;

	@Prop(String)
	error: string;

	@Prop(String)
	labelClasses: string;

	@Prop(String)
	inputClasses: string;

	@Prop(String)
	help: string;

	@Prop([String, Number])
	value: string | number;

	@Prop(String)
	addonRightIcon: string;

	@Prop(String)
	addonLeftIcon: string;

	get hasIcon() {
		const {addonRight, addonLeft} = this.$slots
		return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
	}
}
</script>
<style>

a.help{
	display:block;
	position: absolute;
	top:0;
	right:0;
	padding-top:18px;
}

</style>

// Mixin for generating new styles
@mixin btn-styles($btn-color, $btn-states-color) {
	border: 1px solid $btn-color;
	color: $white-color;
	background-color: $btn-color;
	cursor: pointer;

	&:hover,
	&:focus,
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		background-color: $btn-states-color;
		color: $white-color;
		border-color: $btn-states-color;
	}

	&.disabled,
	&:disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
			background-color: $transparent-bg;
			border-color: $btn-color;
		}
	}


	&.btn-outline {
		color: $btn-color;
		background-color: $transparent-bg;
		border: 1px solid $btn-color;
		@include opacity(1);

		&:hover,
		&:focus,
		&:active,
		&.active,
		.open > &.dropdown-toggle{
				background-color: $btn-states-color;
				color: $white-color;
				border: 1px solid $btn-states-color;
		}

		.caret{
				border-top-color: $white-color;
		}
	}

	&.btn-link{
			border-color: $transparent-bg !important;
			background-color: $transparent-bg !important;
			color: $default-color;

			&:hover,
			&:focus,
			&:active{
					text-decoration: none;
					border-color: $transparent-bg;
					color: $default-states-color;
			}

	}

	.caret{
			border-top-color: $btn-color;
	}
}


@mixin btn-size($padding-vertical, $padding-horizontal, $font-size, $border){
	 font-size: $font-size;
	 border-radius: $border;
	 padding: $padding-vertical $padding-horizontal;

	 &.btn-round{
			 padding: $padding-vertical + 1 $padding-horizontal;
	 }

	 &.btn-simple{
			 padding: $padding-vertical + 2 $padding-horizontal;
	 }

}

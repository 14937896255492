<template>
	<card :title="localize('Prizes')">
		<button
			type="button"
			class="btn-add btn btn-primary btn-fill float-right"
			@click="addItem()"
			:title="localize('Add')"
		><icon :icon="['far', 'plus']" fixed-width size="lg" /></button>
		<prize-picker
 			v-if="showModal"
			@close="showModal = false"
			v-on:addPrize="addPrize"
		></prize-picker>
		<b-table
			v-if="prizes && prizes.length > 0"
			:items="prizes"
			:fields="fields"
			striped
			:label-sort-asc="localize('Click to sort Ascending')"
			:label-sort-desc="localize('Click to sort Descending')"
		>
			<template v-slot:cell(prizePatternGroupId)="row">
				<span
					:title="localize('Prize Id: ') + row.item.id"
				>{{ getPrizePatternName(row.item) }}</span>
			</template>
			<template v-slot:cell(prize)="row">
				<b-row>
					<b-col v-if="!item.isProbabilityGame">
						<b-form-group :label="localize('% of revenue')">
							<span class="nobr">
								<input
									v-model="prizes[row.index].turnoverPayoutPercent"
									type="number"
									placeholder="<%>"
									:max="100"
									:min="0"
									:step="0.1"
									aria-valuemin="1"
									aria-valuemax="100"
									class="form-control percentage"
									size="small"
									required
									aria-required
								> % {{ localize("of revenue") }}
							</span>
						</b-form-group>
					</b-col>
					<b-col v-if="item.isProbabilityGame">
						<b-form-group :label="localize('Fixed value prize')">
							<span class="nobr">
								<input
									v-model="prizes[row.index].fixedPayoutValue"
									id="fixedPayoutValue"
									type="number"
									placeholder="<number>"
									:max="100000"
									:min="0"
									:setp="1"
									aria-valuemin="1"
									aria-valuemax="100"
									class="form-control percentage"
									size="small"
									required
									aria-required
								> {{ localize("NOK") }}
							</span>
						</b-form-group>
					</b-col>
					<b-col v-if="item.isProbabilityGame">
						<b-form-group :label="localize('Highest prize only')">
							<checkbox v-model="prizes[row.index].onlyWinHighestPatternPrize"></checkbox>
						</b-form-group>
					</b-col>
				</b-row>
			</template>
			<template v-slot:cell(extraRuleType)="row">
				<extra-rule
					:prize="row.item"
					:gridTypeId="item.gridTypeId"
					@change="updateExtraRule(row.item, $event)"
				></extra-rule>
			</template>
			<template v-slot:cell(actions)="row">
				<a
					v-b-tooltip.hover.top="localize('Delete')"
					class="btn-danger btn-link"
					@click.stop="deleteItem(row.index, prizes[row.index])"
				>
					<icon :icon="['far', 'trash-alt']" fixed-width size="lg" />
				</a>
			</template>
		</b-table>
		<div
			:class="{
				danger: totalPrize > 65
			}"
		>{{ localize("Prize Total") }}: {{ totalPrize }} {{ localize("% of revenue") }} + {{ localize("Extra Rules") }}.</div>
	</card>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel, TicketColor, TableField } from '@/models';
import { Game, IPrize as Prize, IGridType as GridType, IPrizePatternGroup as PrizePatternGroup, ExtraRule, ExtraRuleType, IPrizePattern as PrizePattern } from '@/services/api';
import Card from '@/components/lbd/Cards/Card.vue';
import { Select, Option } from 'element-ui';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import PrizePicker from './PrizePicker.vue';
import ExtraRuleForm from './ExtraRuleForm.vue';


@Component({
	name: 'PrizeList',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"prize-picker": PrizePicker,
		"extra-rule": ExtraRuleForm
	}
})
export default class PrizeList extends BaseList {
	private prizes: Array<Prize> = new Array<Prize>();	//Reactive property for item property that is not a value type (and are not automatically reactive).
	private showModal: boolean = false;
	private fields: Array<TableField> = new Array<TableField>();

	created() {
		this.updatePrizesFromItem(null, null);
		this.fields.push({ key: "prizePatternGroupId", label: this.localize("Pattern") });
		this.fields.push({ key: "prize", label: this.localize("Prize") });
		// if(this.item.isProbabilityGame) {
		// 	this.fields.push({ key: "fixedPayoutValue", label: this.localize("Prize") });
		// 	this.fields.push({ key: "onlyWinHighestPatternPrize", label: this.localize("High only") });
		// } else {
		// 	this.fields.push({ key: "turnoverPayoutPercent", label: this.localize("Prize"), formatter: this.turnoverPayoutPercentFormatter });
		// }
		this.fields.push({ key: "extraRuleType", label: this.localize("Extra Rule") });
		this.fields.push({ key: "actions", label: this.localize("Actions") });

	}

	@Prop({type: Object, default: undefined, required: true})
	public item: Game;

	@Prop({type: Boolean, default: false, required: true})
	public isProbabilityGame: boolean;

	@Watch("item")
	public updatePrizesFromItem(val: Game, oldVal: Game): void {
		if(!this.item || !this.item.prizes || !Array.isArray(this.item.prizes)) return;
		this.prizes = this.item.prizes.sort(this.prizeSorting);
		Logger.log(LogLevel.Debug, "PrizeList.updatePrizesFromItem() - Item:", this.prizes);
	}

	@Watch("prizePatternGroupList")
	public updatePrizeSorting(val: Array<PrizePatternGroup>, oldVal: Array<PrizePatternGroup>): void {
		if(!this.item || !this.item.prizes || !Array.isArray(this.item.prizes)) return;
		this.prizes = this.item.prizes.sort(this.prizeSorting);
		Logger.log(LogLevel.Debug, "PrizeList.updatePrizeSorting() - Item:", this.prizes);
	}

	@Watch("prizes")
	private prizesChanged(value: Array<Prize>, oldVal: Array<Prize>): void {
		this.$emit("change", this.prizes);
		Logger.log(LogLevel.Debug, "GameForm -> PrizeLiszt.prizesChanged()", this.prizes);
	}

	private extraRuleFields: Array<TableField> = [
		{
			key: "extraRulePot",
			label: this.localize("Pot")
		},
		{
			key: "extraRuleType",
			label: this.localize("Type")
		},
		{
			key: "extraRuleValue",
			label: this.localize("Value")
		},
		{
			key: "extraRulePotPayoutPercent",
			label: this.localize("Prize")
		}
	];

	private turnoverPayoutPercentFormatter(value: number, key: string, item: Prize): string {
		return value + "%";
	}

	private extraRulePayoutFormatter(value: ExtraRule, key: string, item: Prize): string {
		if(!item.extraRule || !item.extraRule.potPayoutPercent) return "";
		return item.extraRule.potPayoutPercent + "%";
	}

	private get prizeSorting(): (a: Prize, b: Prize) => number {
		return (a: Prize, b: Prize) => {
			if(!this.prizePatternList || !Array.isArray(this.prizePatternList) || this.prizePatternList.length < 1) return 0;
			let group: PrizePatternGroup = this.prizePatternGroupList.find((element: PrizePatternGroup): boolean => element.id == a.prizePatternGroupId);
			if(!group) return 0;
			if(group.isIndependantOfOtherPrizes) return 1;
			let noOfPatternsA: number = group.requiredPrizePatternsToTrigger;
			let noOfFieldsA: number = this.prizePatternList
				.filter((element: PrizePattern): boolean => group.prizePatternIds.includes(element.id))		// find all PrizePatterns in PrizePatternGroup
				.map((element2: PrizePattern): number => element2.pattern.length)							// extract number of fields in pattern
				.reduce((previousValue: number, currentValue: number, currentIndex: number, array: Array<number>): number => currentValue + previousValue);
			group = this.prizePatternGroupList.find((element: PrizePatternGroup): boolean => element.id == b.prizePatternGroupId);
			if(!group) return 0;
			let noOfPatternsB: number = group.requiredPrizePatternsToTrigger;
			let noOfFieldsB: number = this.prizePatternList
				.filter((element: PrizePattern): boolean => group.prizePatternIds.includes(element.id))		// find all PrizePatterns in PrizePatternGroup
				.map((element2: PrizePattern): number => element2.pattern.length)							// extract number of fields in pattern
				.reduce((previousValue: number, currentValue: number, currentIndex: number, array: Array<number>): number => currentValue + previousValue);
			return ((noOfPatternsA - noOfPatternsB) * 1000) + (noOfFieldsA - noOfFieldsB);
		}
	}

	private getPrizePatternName(item: Prize): string {
		if(!this.prizePatternGroupList || !Array.isArray(this.prizePatternGroupList) || this.prizePatternGroupList.length < 1) return item.prizePatternGroupId + "";
		let group: PrizePatternGroup = this.prizePatternGroupList.find((element: PrizePatternGroup) => element.id == item.prizePatternGroupId);
		if(group) return group.name;
		return item.prizePatternGroupId + "";
	}

	/** @method addItem Navigates the user to a form to create a new item for the current list. */
	protected addItem(): void {
		this.showModal = true;
	}

	/** @method deleteItem Deletes the item in the list
	 * @param index The list index of the item to delete. This is used remove the item from locally cached data.
	 * @param item The item to delete. */
	protected deleteItem(index: number, item: { id: number}, type: string = ""): void {
		if (!confirm(this.localize("Are you sure you want to delete this item?"))) return;
		this.prizes.splice(
			this.prizes.indexOf(
				this.prizes.find((element: Prize) => element.id == item.id)
			),
			1
		);
	}

	private addPrize(value: Prize): void {
		value.uniqueForEachGrid = this.item.isProbabilityGame;
		let prizes: Array<Prize> = (this.prizes || new Array<Prize>()).slice();
		prizes.push(value);
		prizes.sort(this.prizeSorting);
		this.prizes = prizes;
		this.showModal = false;
		Logger.log(LogLevel.Info, "User added a new Prize to the Game.");
	}

	private updateExtraRule(prize: Prize, rule: ExtraRule): void {
		prize.extraRule = rule;
	}

	private get totalPrize(): number {
		return (this.prizes || new Array<Prize>())
			.map((element: Prize): number => element.turnoverPayoutPercent)
			.reduce((a: number, b: number): number => a + Number(b), 0);
	}

}

</script>

<style scoped>

.form-group {
	margin-bottom: 0;
}

.form-control {
	display:inline-block !important;
	padding:0 12px !important;
	width: 56px;
	height: 32px;
}

table.extra-rules {
	margin:0;
}

table.extra-rules > thead > tr > th,
table.extra-rules th{
	padding-top:0 !important;
	padding-bottom:0 !important;
}

.select-value{
	width:60px;
}

.nobr{
	white-space: nowrap;
}

.danger{
	color:#FB404B;
}

#fixedPayoutValue{
	min-width: 78px;
}

</style>

/*		 General overwrite		 */
body,
.wrapper{
	min-height: 100vh;
	position: relative;
}
a{
	color: $info-color;

	&:hover, &:focus{
		color: $info-states-color;
		text-decoration: none;
	}
}

a:focus, a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner{
	outline:0;
}
.ui-slider-handle:focus,
.navbar-toggle,
input:focus {
	outline : 0 !important;
}

/*					 Animations							*/
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert{
	@include transition($general-transition-time, $transition-linear);
}


.animation-transition-fast,
.sidebar .nav .collapse a > span,
.sidebar .sidebar-wrapper > .nav p,
.sidebar .user .info > a > span,
.sidebar .logo a.logo-normal,
.sidebar .user .info .collapse .nav a > span {
	@include transition($fast-transition-time, $transition-linear);
}

.sidebar .nav a,
.table > tbody > tr .td-actions .btn{
	@include transition($fast-transition-time, $transition-ease-in);
}

.btn{
	@include transition($ultra-fast-transition-time, $transition-ease-in);
}
.fa{
	width: 18px;
	text-align: center;
}
.margin-top{
	margin-top: 50px;
}

.wrapper{
	position: relative;
	top: 0;
	height: 100vh;
}

.card .map{
	height: 400px;

	.map-big{
		height: 350px;
	}
}


// documentation

.page-header{
	.page-header-image{
		background-position: center center;
		background-size: cover;
		height: 450px;
		overflow: hidden;
		width: 100%;
		z-index: 1;
	}
	.title-container{
		color: #fff;
		position: relative;
		top: 200px;
		z-index: 3;
	}
	.filter:after{
		background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #9368e9 0%, #943bea 100%) repeat scroll 0 0 / 150% 150%;
		content: "";
		display: block;
		height: 450px;
		left: 0;
		opacity: 0.77;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2;
	}
}

// accordion

.form-control,
.input-group-addon,
.tagsinput,
.navbar .alert,
.card-collapse.collapse{
	-webkit-transition: all 300ms linear;
	-moz-transition: all 300ms linear;
	-o-transition: all 300ms linear;
	-ms-transition: all 300ms linear;
	transition: all 300ms linear;
}

.card{
	.card-body.content-full-width{
		padding: 15px 0px 10px 0px;

		.accordions{
			.card-title{
				a{
					padding: 0px 0px 5px 15px;
				}
			}
		}
	}
}

<template>
	<card :title="title">
		<template v-slot:cell(header) class="form-header">
			<h4 class="card-title">{{ title }}</h4>
			<a
				class="btn-close"
				@click.prevent="closeForm()"
				:title="localize('Close') + ' (' + localize('Shortcut') + ': Esc)'"
			>
				<icon :icon="['far', 'times']" fixed-width size="lg" border />
			</a>
		</template>
		<form @submit.prevent="saveItem">
			<div class="row">
				<fg-input
					class="col-6"
					type="text"
					:label="localize('Name')"
					:placeholder="localize('text')"
					v-model="item.name"
					required
					aria-required
				></fg-input>
			</div>
			<div class="row">
				<fg-input
					class="col-6"
					:label="localize('Description')"
				>
					<el-input
						type="textarea"
						:rows="4"
						:placeholder="localize('text')"
						v-model="item.description"
						required
						aria-required
					></el-input>
				</fg-input>
			</div>
			<div class="row">
				<div class="col-12">
					<label class="control-label">{{ localize("Blocks") }}</label>
					<br>
					<h2>{{ localize('Total') }}: {{ blockIds.length }} {{ localize('blocks') }}</h2>
					<block-list
						:blockIds="blockIds"
						:startTime="item.startTime"
						v-on:changed="listChanged"
					></block-list>
				</div>
			</div>
			<div class="row">
				<fg-input
					class="col-2"
					type="time"
					:label="localize('Start Time')"
					:placeholder="localize('hh:mm')"
					v-model="item.startTime"
					required
					aria-required
				></fg-input>
			</div>
			<hr>
			<button type="submit" class="btn btn-primary btn-fill float-right">{{ localize("Save") }}</button>
			<div class="clearfix"></div>
		</form>
	</card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option, Input } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import BlockList from './BlockList.vue';
import { IGamePlan, GamePlan, IGame as Game, BlockId } from '@/services/api';


@Component({
	name: 'GamePlanForm',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"el-input": Input,
		"fg-input": fgInput,
		BlockList
	}
})
export default class GamePlanForm extends BaseForm {
	private blockIds: Array<BlockId> = new Array<BlockId>();	//Reactive property for item property that is not a value type (and are not automatically reactive).

	public get title(): string {
		let title: string = this.isNew ? "New Game Plan" : "Edit Game Plan";
		return this.localize(title);
	}

//#region Overridable Form Life Cycle Methods: 
	//	loadItem(), itemReactivePropertyExtractor(), saveItem(), validateForm(), itemReactivePropertyInserter(), itemSavedCallback(), listRoute(), closeForm()

	protected get itemReactivePropertyExtractor(): (item: any) => void {
		return ((item: GamePlan) => {
			this.blockIds =  item.blockIds;
		});
	}

	protected get validateForm(): () => boolean {
		return (() => {
			//TODO: get all required fields and check their value
			return true;
		});
	}

	protected get itemReactivePropertyInserter(): (item: any) => void {
		return ((item: GamePlan) => {
			item.blockIds = this.blockIds;
		});
	}

//#endregion

	private listChanged(value: Array<BlockId>): void {
		this.blockIds = value;
	}

}
</script>

<style scoped>

.card .card{
	margin-top: 0 !important;
}

h2{
	position:absolute;
	top:25px;
	right:15px;
	margin:0;
	padding:0;
	font-size: 12px;
}

</style>

<template>
	<card :title="title">
		<template v-slot:cell(header) class="form-header">
			<h4 class="card-title">{{ title }}</h4>
			<a
				class="btn-close"
				@click.prevent="closeForm()"
				:title="localize('Close') + ' (' + localize('Shortcut') + ': Esc)'"
			>
				<icon :icon="['far', 'times']" fixed-width size="lg" border />
			</a>
		</template>
		<form @submit.prevent="saveItem">
			<div class="row">
				<fg-input
					class="col-6"
					type="text"
					:label="localize('Name')"
					:placeholder="localize('text')"
					v-model="item.name"
					required
					aria-required
				></fg-input>
			</div>
			<div class="row">
				<fg-input
					class="col-6"
					:label="localize('Description')"
				>
					<el-input
						type="textarea"
						:rows="4"
						:placeholder="localize('text')"
						v-model="item.description"
						required
						aria-required
					></el-input>
				</fg-input>
			</div>
			<div class="row">
				<div class="col-12">
					<label class="control-label">{{ localize("Game Plans") }}</label>
					<br>
					<ul class="gamePlanPicker">
						<li
							v-for="(gamePlan, index) in gamePlanList"
							:key="index"
							:class="{
								selectedGamePlan: gamePlan.id == selectedGamePlanId
							}"
						>
							<a
								@click="selectGamePlan(gamePlan.id)"
								:style="{
									backgroundColor: getGamePlanColor(gamePlan.id)
								}"
								:title="gamePlan.description"
							>{{ gamePlan.name }}</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<label class="control-label">{{ localize("Bulk Assign") }}</label>
					<br>
					<ul class="weekDayPicker">
						<li
							v-for="(day, index) in weekDays"
							:key="index"
						>
							<a
								@click="selectAllWeekDays(index)"
								class="btn btn-primary btn-fill"
								:disabled="!hasItemLoaded || !hasScheduledGamePlansLoaded || !hasGamePlansLoaded"
							>{{ day }}</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<label class="control-label">{{ localize("Assign to dates") }}</label>
					<ul class="calendar">
						<li>
							<datepicker
								id="picker"
								ref="picker"
								:inline="true"
								format="yyyy-MM-dd"
								:monday-first="true"
								:highlighted="state.highlighted"
								:disabledDates="state.disabledDates"
								:open-date="firstMonth"
								@selected="addDate"
								:dayCellContent="dateCellFormatter"
							>
							</datepicker>
						</li>
						<li>
							<datepicker
								id="picker"
								ref="picker"
								:inline="true"
								format="yyyy-MM-dd"
								:monday-first="true"
								:highlighted="state.highlighted"
								:disabledDates="state.disabledDates"
								:open-date="secondMonth"
								@selected="addDate" 
								:dayCellContent="dateCellFormatter"
							>
							</datepicker>
						</li>
						<li>
							<datepicker
								id="picker"
								ref="picker"
								:inline="true"
								format="yyyy-MM-dd"
								:monday-first="true"
								:highlighted="state.highlighted"
								:disabledDates="state.disabledDates"
								:open-date="thirdMonth"
								@selected="addDate"
								:dayCellContent="dateCellFormatter"
							>
							</datepicker>
						</li>
					</ul>
				</div>
			</div>

			<hr>
			<button
				type="submit"
				class="btn btn-primary btn-fill float-right"
			>{{ localize("Save") }}</button>
			<div class="clearfix"></div>
		</form>
	</card>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Select, Option, Input } from 'element-ui';
import Datepicker from 'vuejs-datepicker';
//import {en, no} from 'vuejs-datepicker/dist/locale';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError, Localizer } from '@/utilities';
import { LogLevel, Day } from '@/models';
import { DatepickerState } from '@/models/datepicker-state';
import { ScheduledGamePlanService } from '@/services';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import { IGameSchedule, GameSchedule as Schedule, ScheduledGamePlan, GameSchedule, IScheduledGamePlan, IGamePlan } from '@/services/api';


@Component({
	name: 'ScheduleForm',
	components: {
		Card,
		Checkbox,
		Datepicker,
		"el-select": Select,
		"el-option": Option,
		"el-input": Input,
		"fg-input": fgInput
	}
})
export default class ScheduleForm extends BaseForm {
	private scheduledGamePlanService: ScheduledGamePlanService = new ScheduledGamePlanService();
	private scheduledGamePlans: Array<IScheduledGamePlan> = new Array<IScheduledGamePlan>();	//Reactive property for item property that is not a value type (and are not automatically reactive).
	private selectedGamePlanId: number = 0;
	private state: DatepickerState = new DatepickerState();
	private colors: Array<string> = ["AntiqueWhite","Aqua","Aquamarine","Bisque","BlanchedAlmond","Blue","BlueViolet","Brown","BurlyWood","CadetBlue","Chartreuse","Chocolate","Coral","CornflowerBlue","Cornsilk","Crimson","Cyan","DarkBlue","DarkCyan","DarkGoldenRod","DarkGray","DarkGrey","DarkGreen","DarkKhaki","DarkMagenta","DarkOliveGreen","Darkorange","DarkOrchid","DarkRed","DarkSalmon","DarkSeaGreen","DarkSlateBlue","DarkSlateGray","DarkSlateGrey","DarkTurquoise","DarkViolet","DeepPink","DeepSkyBlue","DimGray","DimGrey","DodgerBlue","FireBrick","ForestGreen","Fuchsia","Gainsboro","Gold","GoldenRod","Gray","Grey","Green","GreenYellow","HotPink","IndianRed","Indigo","Khaki","Lavender","LavenderBlush","LawnGreen","LemonChiffon","LightBlue","LightCoral","LightCyan","LightGoldenRodYellow","LightGray","LightGrey","LightGreen","LightPink","LightSalmon","LightSeaGreen","LightSkyBlue","LightSlateGray","LightSlateGrey","LightSteelBlue","LightYellow","Lime","LimeGreen","Linen","Magenta","Maroon","MediumAquaMarine","MediumBlue","MediumOrchid","MediumPurple","MediumSeaGreen","MediumSlateBlue","MediumSpringGreen","MediumTurquoise","MediumVioletRed","MidnightBlue","MistyRose","Moccasin","NavajoWhite","Navy","Olive","OliveDrab","Orange","OrangeRed","Orchid","PaleGoldenRod","PaleGreen","PaleTurquoise","PaleVioletRed","PapayaWhip","PeachPuff","Peru","Pink","Plum","PowderBlue","Purple","Red","RosyBrown","RoyalBlue","SaddleBrown","Salmon","SandyBrown","SeaGreen","Sienna","Silver","SkyBlue","SlateBlue","SlateGray","SlateGrey","SpringGreen","SteelBlue","Tan","Teal","Thistle","Tomato","Turquoise","Violet","Wheat","Yellow","YellowGreen"]; //.sort(() => Math.random() - 0.5);
	private hasItemLoaded: boolean = false;
	private hasScheduledGamePlansLoaded: boolean = this.scheduledGamePlanList && Array.isArray(this.scheduledGamePlanList) && this.scheduledGamePlanList.length >= 0;
	private hasGamePlansLoaded: boolean = this.gamePlanList && Array.isArray(this.gamePlanList) && this.gamePlanList.length >= 0;
	private hasPainted: boolean = false;


	public created() {
		if(this.gamePlanList && Array.isArray(this.gamePlanList) && this.gamePlanList.length > 0)this.selectedGamePlanId = this.gamePlanList[0].id;
		this.state = <DatepickerState>{
			highlighted: {
				dates: this.scheduledGamePlans.map((element: IScheduledGamePlan): Date => element.day)
			},
			disabledDates: {
				to: new Date(new Date().setDate(new Date().getDate()-1))	// Don't allow to schedule passed dates.
			}
		};
		this.hasItemLoaded = (this.item && this.item.scheduledGamePlansIds && Array.isArray(this.item.scheduledGamePlansIds));
		this.hasScheduledGamePlansLoaded = this.scheduledGamePlanList && Array.isArray(this.scheduledGamePlanList) && this.scheduledGamePlanList.length >= 0;
		this.hasGamePlansLoaded = this.gamePlanList && Array.isArray(this.gamePlanList) && this.gamePlanList.length >= 0;
		this.hasPainted = false;
		if(this.item && !this.hasPainted && this.hasItemLoaded && this.hasGamePlansLoaded && this.hasScheduledGamePlansLoaded) {
			this.itemReactivePropertyExtractor(this.item);
		}
		// 	(<any>this.$children[0].$children[0]).isLeftNavDisabled = () => true;
		// 	(<any>this.$children[0].$children[1]).isLeftNavDisabled = () => true;
		// 	(<any>this.$children[0].$children[2]).isLeftNavDisabled = () => true;
		// 	(<any>this.$children[0].$children[0]).isRightNavDisabled = () => true;
		// 	(<any>this.$children[0].$children[1]).isRightNavDisabled = () => true;
		// 	(<any>this.$children[0].$children[2]).isRightNavDisabled = () => true;
		Logger.log(LogLevel.Debug, "ScheduleForm.created() - State:", this);
	}

	@Watch("scheduledGamePlanList")	// Painting calendars depends on this data to be loaded, loading order is random, have to watch
	public scheduledGamePlansLoaded(value: Array<IScheduledGamePlan>, oldValue: Array<IScheduledGamePlan>): void {
		this.hasScheduledGamePlansLoaded = true;
		if(!this.hasPainted && this.hasItemLoaded && this.hasGamePlansLoaded) {
			this.itemReactivePropertyExtractor(this.item);
		}
	}

	@Watch("gamePlanList")	// Painting calendars depends on this data to be loaded, loading order is random, have to watch
	public gamePlansLoaded(value: Array<IScheduledGamePlan>, oldValue: Array<IScheduledGamePlan>): void {
		if(this.selectedGamePlanId == 0 && this.gamePlanList.length > 0)this.selectedGamePlanId = this.gamePlanList[0].id;
		this.hasGamePlansLoaded = true;
		if(!this.hasPainted && this.hasItemLoaded && this.hasScheduledGamePlansLoaded) {
			this.itemReactivePropertyExtractor(this.item);
		}
	}

	public get title(): string {
		let title: string = this.isNew ? "New Schedule" : "Edit Schedule";
		return this.localize(title);
	}

//#region Overridable Form Life Cycle Methods: 
	//	loadItem(), itemReactivePropertyExtractor(), saveItem(), validateForm(), itemReactivePropertyInserter(), itemSavedCallback(), listRoute(), closeForm()

	protected get itemReactivePropertyExtractor(): (item: any) => void {
		return ((item: GameSchedule) => {
			if(!this.hasScheduledGamePlansLoaded) return;
			if(!this.hasGamePlansLoaded) return;
			if(!item.scheduledGamePlansIds && !Array.isArray(item.scheduledGamePlansIds)) throw new Error("Schedule.scheduledGamePlansIds does not exist!");
			this.scheduledGamePlans =  this.scheduledGamePlanList.filter((element: IScheduledGamePlan) => item.scheduledGamePlansIds.includes(element.id));
			this.state.highlighted.dates = this.scheduledGamePlans.map((element: IScheduledGamePlan): Date => element.day);
			setTimeout(() => {this.dateCellPainter();}, 200);
			this.hasItemLoaded = true;
		});
	}

	protected get validateForm(): () => boolean {
		return (() => {
			//TODO: get all required fields and check their value
			return true;
		});
	}

	protected get itemReactivePropertyInserter(): (item: any) => void {
		return ((item: GameSchedule) => {
			item.scheduledGamePlansIds = this.scheduledGamePlans.map((element: IScheduledGamePlan) => element.id); // Required all ScheduledGamePlans to be created in advance!
		});
	}

//#endregion

	private get weekDays(): Array<string> {
		return Localizer.getDatepickerLanguage().daysLong;
	}

	private dateCellFormatter(value: Day): string {
		let dayDate: Date = (new Date(value.timestamp));
		return "<span data-date=\"" + this.formatDate(dayDate) + "\">" + dayDate.getDate() + "</span>";
	}

	private getGamePlanColor(value: number): string {
		return this.colors[value % this.colors.length];
	}

	private dateCellPainter(): void {
		Logger.log(LogLevel.Trace, "ScheduleForm.dateCellPainter() - Painting cells: ", this.state);
		let elements: NodeListOf<Element> = document.body.querySelectorAll("span[data-date]");
		elements.forEach((element: Element) => {
			let parent: HTMLElement = element.parentElement;
			if(!parent.classList.contains("highlighted")) return;
			let plan: IScheduledGamePlan = this.scheduledGamePlans.find((element2: IScheduledGamePlan) => this.formatDate(element2.day) == element.getAttribute("data-date"));
			let gamePlanId: number = plan ? plan.gamePlanId : 0;
			parent.style.background = this.getGamePlanColor(gamePlanId);
		});
		this.hasPainted = true;
	}

	// private get scheduleIsRunning(): boolean {
	// 	if(
	// 		this.hasItemLoaded &&
	// 		this.item &&
	// 		this.item.state == "Stopped"
	// 	) return false;
	// 	return true;
	// }

	private get firstMonth(): Date {
		let start: Date = new Date();
		let first: Date = new Date(start.getFullYear(), start.getMonth(), 1);
		return first;
	}

	private get secondMonth(): Date {
		let start: Date = this.firstMonth;
		let second: Date;
		if(start.getMonth() == 11) {
			second = new Date(start.getFullYear() + 1, 0, 1);
		} else {
			second = new Date(start.getFullYear(), start.getMonth() + 1, 1);
		}
		return second;
	}

	private get thirdMonth(): Date {
		let start: Date = this.secondMonth;
		let third: Date;
		if(start.getMonth() == 11) {
			third = new Date(start.getFullYear() + 1, 0, 1);
		} else {
			third = new Date(start.getFullYear(), start.getMonth() + 1, 1);
		}
		return third;
	}

	private selectGamePlan(value: number): void {
		this.selectedGamePlanId = value;
		Logger.log(LogLevel.Info, "User choose Game Plan " + this.gamePlanList.find((element: IGamePlan) => element.id == this.selectedGamePlanId).name + " (Id: " + this.selectedGamePlanId + ").");
	}

	private toggleOffDate(value: Date, domElement: HTMLElement): boolean {
		let dateIsScheduled: boolean = this.scheduledGamePlans
			.map((element: IScheduledGamePlan): Date => element.day)
			.map((element: Date) => this.formatDate(element)).includes(this.formatDate(value));
		if(!dateIsScheduled) return false;	// Nothing to toggle off!
		let oldScheduledGamePlanId: number;
		let scheduledGamePlanOnThisDate: IScheduledGamePlan = this.scheduledGamePlans.find((element: IScheduledGamePlan): boolean => this.formatDate(element.day) == this.formatDate(value));
		if(scheduledGamePlanOnThisDate) {	// This date has a GamePlan scheduled -> Remove it!
			oldScheduledGamePlanId = scheduledGamePlanOnThisDate.id;
			if(oldScheduledGamePlanId && oldScheduledGamePlanId > 0) {	// it is a saved ScheduledGamePlan... delete it
				this.scheduledGamePlanService.deleteItem(scheduledGamePlanOnThisDate.id).then(() => {
					this.$store.commit('cacheStore/reloadScheduledGamePlanList');
				});
			}
			let scheduledGamePlanIndex: number = this.scheduledGamePlans.findIndex((element: IScheduledGamePlan): boolean => this.formatDate(element.day) == this.formatDate(value));
			this.scheduledGamePlans.splice(scheduledGamePlanIndex, 1);
		}
		domElement.style.backgroundColor = "#fff";
		Logger.log(LogLevel.Info, "User removed schedule on " + this.formatDate(value) + ".");
		return true;
	}

	private selectAllWeekDays(day: number): void {
		// if(this.scheduleIsRunning) return;
		if(Localizer.firstDayOfWeek() == "Monday") day = (day > 5) ? 0: day + 1;
		let scheduledGamePlansToDelete = this.scheduledGamePlans.filter((element: IScheduledGamePlan) => element.day && element.day.getDay() == day);
		if(scheduledGamePlansToDelete.length = 0) this.scheduledGamePlanService.deleteItems(scheduledGamePlansToDelete.map((element: IScheduledGamePlan): number => element.id)).then(() => {
			scheduledGamePlansToDelete.forEach((element: IScheduledGamePlan):void => {
				let scheduledGamePlanIndex: number = this.scheduledGamePlans.findIndex((element2: IScheduledGamePlan): boolean => element2.id == element.id);
				this.scheduledGamePlans.splice(scheduledGamePlanIndex,1);
			});
			this.state.highlighted.dates = this.scheduledGamePlans.map((element: IScheduledGamePlan): Date => element.day);
			setTimeout(() => {this.dateCellPainter();}, 200);
			this.$store.commit('cacheStore/reloadScheduledGamePlanList');
		});
		this.scheduledGamePlans = this.scheduledGamePlans.filter((element: IScheduledGamePlan) => element.day && element.day.getDay() != day);
		if(this.selectedGamePlanId > 0) {
			let startDate: Date = new Date(new Date().setDate(new Date().getDate()));
			let endDate: Date;	// First day of the month after the last month in calendar (thirdMonth)
			if(this.thirdMonth.getMonth() == 11) {
				endDate = new Date(this.thirdMonth.getFullYear() + 1, 0, 1);
			} else {
				endDate = new Date(this.thirdMonth.getFullYear(), this.thirdMonth.getMonth() + 1, 1);
			}
			let newScheduledGamePlans: Array<ScheduledGamePlan> = new Array<ScheduledGamePlan>();
			for(let d = new Date(startDate); d < endDate; d.setDate(d.getDate() + 1)) {
				if(d.getDay() == day) {
					let newDate: Date = new Date(d);
					if(newDate.getTimezoneOffset() != 0) newDate.setMinutes(-1 * newDate.getTimezoneOffset());	// compensate for TimeZone
					newScheduledGamePlans.push(new ScheduledGamePlan({ id: undefined, gamePlanId: this.selectedGamePlanId, day: newDate }));	// Note: have to create new instance or all dates will be the same date.
					this.state.highlighted.dates.push(new Date(d));
				}
			}
			this.scheduledGamePlanService.createItems(newScheduledGamePlans).then((newIds: Array<number>): void => {
				newScheduledGamePlans.forEach((element: ScheduledGamePlan, index: number): void => { element.id = newIds[index] });
				this.scheduledGamePlans.push(...newScheduledGamePlans);
				this.state.highlighted.dates = this.scheduledGamePlans.map((element: IScheduledGamePlan): Date => element.day);
				setTimeout(() => {this.dateCellPainter();}, 200);
				this.$store.commit('cacheStore/reloadScheduledGamePlanList');
			});
		} else {	//: calendars are repainted with removed dates only.
			this.state.highlighted.dates = this.scheduledGamePlans.map((element: IScheduledGamePlan): Date => element.day);
			setTimeout(() => {this.dateCellPainter();}, 200);
		}
		Logger.log(LogLevel.Info, "User scheduled " + this.gamePlanList.find((element: IGamePlan) => element.id == this.selectedGamePlanId).name + " (Id: " + this.selectedGamePlanId + ") on " + ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day] + "s.");
	}

	private addDate(value: Date): void {
		if(value.getTimezoneOffset() != 0) value.setMinutes(-1 * value.getTimezoneOffset());	// compensate for TimeZone
		Logger.log(LogLevel.Debug, "ScheduleForm.addDate() - ISO String: " + value.toISOString(), value);
		(<any>this.$children[0].$children[0]).selectedDate = null;	// This -> Card -> DatePicker.selectedDate
		(<any>this.$children[0].$children[1]).selectedDate = null;	// This -> Card -> DatePicker.selectedDate
		(<any>this.$children[0].$children[2]).selectedDate = null;	// This -> Card -> DatePicker.selectedDate
		//(<any>this.$children[0].$children[2]).setPageDate()
		let domElement: HTMLElement = (<HTMLElement>event.target);
		if(domElement.hasAttribute("data-date")) domElement = domElement.parentElement;
		// if(this.scheduleIsRunning) {
		// 	let dateIsScheduled: boolean = this.scheduledGamePlans
		// 		.map((element: IScheduledGamePlan): Date => element.day)
		// 		.map((element: Date) => this.formatDate(element)).includes(this.formatDate(value));
		// 	if(!dateIsScheduled) domElement.style.backgroundColor = "#fff";
		// 	return;
		// }
		if(this.toggleOffDate(value, domElement)) return;	// Toggled off scheduled date, don't do anything else.
		if(!this.selectedGamePlanId) {
			Logger.log(LogLevel.Debug, "ScheduleForm.addDate() - Can't add date, no Game Plan Selected!");
			return;	// Can't schedule before selecting a Game Plan
		}
		let newScheduledGamePlan: ScheduledGamePlan = new ScheduledGamePlan({ id: undefined, gamePlanId: this.selectedGamePlanId, day: value });
		this.scheduledGamePlanService.createItem(newScheduledGamePlan).then((value: number) => {
			newScheduledGamePlan.id = value;
			this.scheduledGamePlans.push(newScheduledGamePlan);
			this.$store.commit('cacheStore/reloadScheduledGamePlanList');
		});
		domElement.style.backgroundColor = this.getGamePlanColor(this.selectedGamePlanId);
		Logger.log(LogLevel.Info, "User scheduled Game Plan '" + this.gamePlanList.find((element: IGamePlan) => element.id == this.selectedGamePlanId).name + "' (Id: " + this.selectedGamePlanId + ") on " + this.formatDate(value) + ".");
	}

	private formatDate(value: Date) {
		let dd: number = value.getDate();
		let mm: number = value.getMonth() + 1;
		let yyyy: number = value.getFullYear();
		return yyyy + '-' + (mm < 10 ? "0" + mm: mm) + '-' + (dd < 10 ? "0" + dd: dd);		
	}

}
</script>

<style>

ul.calendar,
ul.gamePlanPicker,
ul.weekDayPicker {
	list-style: none;
	margin:0;
	padding:0;
}

ul.gamePlanPicker li{
	display: inline-block;
	border-bottom: solid 3px transparent;
}

ul.weekDayPicker li {
	display: inline-block;
	margin-right:12px;
	color:#fff;
}

ul.gamePlanPicker li a {
	display: inline-block;
	padding: 3px 12px;
	border: solid 1px #ccc;
	cursor: pointer;
}

ul.gamePlanPicker li.selectedGamePlan,
ul.gamePlanPicker li.selectedGamePlan a {
	border-color: #009FE3;
}

ul.calendar li {
	display: inline-block;
	width:300px;
}

ul.calendar li + li,
ul.gamePlanPicker li + li {
	margin-left:15px;
}

.vdp-datepicker  {
	width: 100%;
}

.vdp-datepicker__calendar {
	width:100%;
}

.btn[disabled] {
	color:#000 !important;
}
</style>

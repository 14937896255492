<template>
	<div class="content">
		<div class="container-fluid">
			<transition name="fade" mode="out-in">
				<!-- your content here -->
				<router-view></router-view>
			</transition>
		</div>
	</div>
</template>

<script lang="ts">
import { Vue, Component, Emit, Inject, Model, Prop, Provide, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent.vue';

@Component({})
export default class Content extends BaseComponent {

}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>

<template>
	<table border="1">
		<tr
			v-for="row in rows"
			:key="row.index"
		>
			<td
				v-for="column in columns"
				:key="column.index"
			>
				<a
					:id="'field-' + row + '-' + column"
					@click="toggleMatrixValue(row, column)"
					:class="{
						'active': matrix.patternAsBoolean[(row * matrix.columns) - matrix.columns + column - 1]
					}"
				></a>
			</td>
		</tr>
	</table>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, Model } from 'vue-property-decorator';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel, Matrix } from '@/models';


@Component({
	name: 'pattern-designer',
	components: {
		Checkbox
	}
})
export default class PatternDesigner extends Vue {
	private matrix: Matrix = new Matrix();

	@Prop({type: Number, default: 0})
	public rows: number;

	@Prop({type: Number, default: 0})
	public columns: number;

	@Prop({type: Array, default: (): Array<number> => []})
	public pattern: Array<number>;

	@Watch("rows")
	private reconfigureMatrixRows(val: number, oldVal: number) {
		this.matrix.rows = val;
		this.matrix.pattern = this.pattern;	//Reapply pattern after Matrix change
		if(oldVal > 0) this.$emit('patternChanged', this.matrix.pattern);	// Don't update pattern whan initializing
		Logger.log(LogLevel.Trace, "PatternDesigner.reconfigureMatrixRows(" + val + ", " + oldVal + ") -> Matrix reconfigured! (" + this.matrix.rows + " x " + this.matrix.columns + ")");
	}

	@Watch("columns")
	private reconfigureMatrixColumns(val: number, oldVal: number) {
		this.matrix.columns = val;
		this.matrix.pattern = this.pattern;	//Reapply pattern after Matrix change
		if(oldVal > 0) this.$emit('patternChanged', this.matrix.pattern);	// Don't update pattern whan initializing
		Logger.log(LogLevel.Trace, "PatternDesigner.reconfigureMatrixColumns(" + val + ", " + oldVal + ") -> Matrix reconfigured! (" + this.matrix.rows + " x " + this.matrix.columns + ")");
	}

	@Watch("pattern")
	private updatePattern(val: Array<number>, oldVal: Array<number>) {
		Logger.log(LogLevel.Trace, "PatternDesigner.updatePattern(" + val + ") -> Repaint pattern!", this.matrix);
		this.matrix.pattern = val;
	}

	private toggleMatrixValue(row: number, column: number): void {
		this.matrix.toggleValueAt(row, column);
		Logger.log(LogLevel.Info, "User toggled field (" + row + ", " + column + ") to " + this.matrix.valueAt(row, column) + "!");
		document.getElementById("field-" + row + "-" + column).className = (this.matrix.valueAt(row, column)) ? "active" : "";
		this.$emit('patternChanged', this.matrix.pattern);
	}

}
</script>

<style scoped>

table{
	margin:15px;
}

a{
	display: block;
	width:48px;
	height:48px;
	background: #eee;
}

a.active{
	background: rgb(170, 12, 223);
}

a:hover{
	cursor: pointer;
}

</style>

<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div class="modal-body">
						<button type="submit" class="btn btn-outline float-right" @click.prevent="$emit('close')">{{ localize("Close") }}</button>
						<card :title="localize('New Prize')">
							<form>
								<div class="row">
									<div class="col-6">
										<label class="control-label">{{ localize("Prize Pattern Group") }}</label>
										<br>
										<el-select
											class="form-group select-default"
											style="width:100%"
											size="small"
											:popperAppendToBody="false"
											:placeholder="localize('Choose')"
											v-model="item.prizePatternGroupId"
											:no-data-text="emptyResultText"
											:loading-text="localize('loading...')"
											required
											aria-required
											:invalid="!(item.prizePatternGroupId>0)"
										>
											<el-option
												class="select-default"
				 								role="option"
												v-for="prize in prizePatternGroupList"
												:key="prize.id"
												:aria-label="localize('Prize') + ': ' + prize.name"
												:aria-selected="item.prizePatternGroupId == prize.id"
												:value="prize.id"
												:label="prize.name"
											></el-option>
										</el-select>
									</div>
								</div>

								<hr>
								<button type="submit" class="btn btn-primary btn-fill float-right" @click.prevent="saveItem">{{ localize("Add") }}</button>
								<div class="clearfix"></div>
							</form>
						</card>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import { Prize, IPrizePatternGroup, PrizePatternGroup, ExtraRule, ExtraRuleType, PayoutSource } from '@/services/api';


@Component({
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"fg-input": fgInput
	}
})
export default class PrizePicker extends BaseForm {
	protected item: Prize = new Prize({	// Initializing is required as PrizeList uses extraRule in its presentation.
		id: undefined,
		extraRule: new ExtraRule({
			type: ExtraRuleType.None,
			value: undefined,
			potId: undefined,
			potPayoutPercent: undefined,
			minimumPotPayout: undefined,
			fixedPayoutValue: 0,
			fixedPayoutSource: PayoutSource.Pot
		}),
		turnoverPayoutPercent: undefined,
		fixedPayoutValue: undefined,
		prizePatternGroupId: undefined,
		uniqueForEachGrid: undefined,
		onlyWinHighestPatternPrize: undefined
	});

	public created() {
		this.itemId = undefined;
		this.item.turnoverPayoutPercent = 0;
	}

	protected get loadItem(): (itemId: number) => void {
		return ((itemId: number) => {});
	}

	/** @method saveItem Saves the the item operated on by this form, then navigates back to the list for this item. */
	protected saveItem() : void{
		this.$emit("addPrize", this.item);
	}

}
</script>

<style scoped>

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-container {
	width: 80%;
	margin: 0px auto;
	padding: 20px 30px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
	transition: all .3s ease;
}

.modal-body {
	margin: 0;
}

.modal-default-button {
	float: right;
}

/*
 * The following styles are auto-applied to elements with transition="modal" when their visibility is toggled by Vue.js.
 * You can easily play with the modal transition by editing these styles.
 */

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}


/* .el-select-dropdown {
	z-index: 12005 !important;
} */

</style>

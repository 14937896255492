<template>
	<card :title="title">
		<template v-slot:cell(header) class="form-header">
			<h4 class="card-title">{{ title }}</h4>
			<a
				class="btn-close"
				@click.prevent="closeForm()"
				:title="localize('Close') + ' (' + localize('Shortcut') + ': Esc)'"
			>
				<icon :icon="['far', 'times']" fixed-width size="lg" border />
			</a>
		</template>
		<form @submit.prevent="saveItem">
			<div class="row">
				<fg-input
					class="col-6"
					type="text"
					:label="localize('Name')"
					:placeholder="localize('text')"
					v-model="item.name"
					required
					aria-required
				></fg-input>
			</div>
			<div class="row">
				<fg-input
					class="col-6"
					:label="localize('Description')"
				>
					<el-input
						type="textarea"
						:rows="4"
						:placeholder="localize('text')"
						v-model="item.description"
						required
						aria-required
					></el-input>
				</fg-input>
			</div>
			<div class="row">
				<fg-input
					class="col-2 percentage"
					type="number"
					:label="localize('Build Percent')"
					:placeholder="'<' + localize('number') + '> %'"
					v-model="item.buildPercent"
					:max="100"
					:min="1"
					aria-valuemin="1"
					aria-valuemax="100"
					required
					aria-required
				></fg-input>
				<fg-input
					class="col-2"
					type="number"
					:label="localize('Prize Cap')"
					:placeholder="localize('number')"
					v-model="item.prizeCap"
					required
					aria-required
				></fg-input>
				<fg-input class="col-1" :label="localize('Jackpot')">
					<checkbox v-model="item.isJackpot">{{ localize("Jackpot") }}</checkbox>
				</fg-input>
				<fg-input class="col-1" :label="localize('Global')">
					<checkbox v-model="item.isGlobal">{{ localize("Global") }}</checkbox>
				</fg-input>
			</div>
			<div class="row">
				<div
					class="col-2"
					type="number"
					placeholder="number"
					v-if="item && item.potValue && item.potValue > 0"
				>
					<label class="control-label">{{ localize("Pot Size") }}</label>
					<div class="input">{{ item.potValue }},-</div>
				</div>
			</div>

			<hr>
			<button type="submit" class="btn btn-primary btn-fill float-right">{{ localize("Save") }}</button>
			<div class="clearfix"></div>
		</form>
	</card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option, Input } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel, TicketColor } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import { IPot, Pot } from '@/services/api';


@Component({
	name: 'PotForm',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"el-input": Input,
		"fg-input": fgInput
	}
})
export default class PotForm extends BaseForm {

	public get title(): string {
		let title: string = this.isNew ? "New Pot" : "Edit Pot";
		return this.localize(title);
	}

//#region Overridable Form Life Cycle Methods: 
	//	loadItem(), itemReactivePropertyExtractor(), saveItem(), validateForm(), itemReactivePropertyInserter(), itemSavedCallback(), listRoute(), closeForm()

	protected get validateForm(): () => boolean {
		return (() => {
			//TODO: get all required fields and check their value
			return true;
		});
	}

//#endregion

}
</script>

<style scoped>
div.input{
	height:22px;
	padding:8px 0px;
	color: #616161;
}
</style>

@import "../variables";
@mixin input_validation($type, $color) {
  .form-group,
  .form-group #{$type} {	//TODO (tm): failed so I commented it out: .form-group.#{$type} {
    .el-input__inner,
    .el-textarea__inner,
    .el-input-number__decrease:hover,
    .el-input-number__increase:hover,
    .el-message-box__input input.invalid {
      &:focus {
        border-color: $color !important;
			}

      @if ($type == '') {
        color: #565656;
      } @else {
        color: $color;
      }
    }
  }
}

.form-group {
	.el-input-number,
	.el-select,
	.el-input__inner {
		width: 100%;
	}
}

@include input_validation('has-success', $success-color);
@include input_validation('has-error', $danger-color);
@include input_validation('', $medium-dark-gray);

<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div class="modal-body">
						<button type="submit" class="btn btn-outline float-right" @click.prevent="$emit('close')">{{ localize("Close") }}</button>
						<card :title="localize('Add Game')">
							<b-table
								:items="gameList"
								:fields="fields"
								show-empty
								striped
								:empty-text="emptyResultText"
								:label-sort-asc="localize('Click to sort Ascending')"
								:label-sort-desc="localize('Click to sort Descending')"
								:empty-filtered-text="localize('There are no items matching your request')"
							>
								<template v-slot:cell(quantity)="row">
									<el-select
										class="form-group select-default"
										style="width:100%"
										size="small"
										:popperAppendToBody="false"
										:placeholder="localize('Choose')"
										v-model="quantity[row.item.id]"
									>
										<el-option
											class="select-default"
			 								role="option"
											v-for="(num, index) in Array.apply(null, {length: 50}).map(Number.call, Number)"
											:key="index"
											:aria-label="localize('Number of Games to Add') + ': ' + num + 1"
											:aria-selected="quantity[row.item.id] == (num + 1)"
											:value="num + 1"
											:label="num + 1"
										></el-option>
									</el-select>
								</template>
								<template v-slot:cell(actions)="row">
									<a
										v-b-tooltip.hover.top="localize('Pick')"
										class="btn btn-primary btn-fill"
										@click.stop="pickItem(row.index, row.item)"
									>
										<icon :icon="['far', 'plus']" fixed-width size="lg" />
									</a>
								</template>
							</b-table>
						</card>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import { ISheetType as SheetType, IGridType as GridType, IGame as Game } from '@/services/api';


@Component({
	name: 'game-picker',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"fg-input": fgInput
	}
})
export default class GamePicker extends BaseForm {
	private quantity: Array<number> = new Array<number>();


	public created() {
		this.gameList.forEach((element: Game, index: number, arr: Array<Game>): void => { this.quantity[element.id] = 1 });
	}

	protected get loadItem(): (itemId: number) => void {
		return ((itemId: number) => {});
	}

	private fields: Array<any> = [
		{
			key: "name",
			label: this.localize("Name"),
			sortable: false
		},
		{
			key: "type",
			label: this.localize("Type"),
			formatter: this.typeFormatter,
			sortable: false
		},
		{
			key: "drawDelay",
			label: this.localize("Draw Delay"),
			sortable: false
		},
		{
			key: "quantity",
			label: this.localize("Quantity"),
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private typeFormatter(value: any, key: string, item: Game): string {
		let sheet: SheetType = this.sheetTypeList.find((elemenmt: SheetType) => elemenmt.id == item.sheetTypeIds[0]);
		if(!sheet) return "";
		let type: GridType = this.gridTypeList.find((element: GridType) => element.id == sheet.gridTypeId);
		if(!type || !type.gridSize) return "";
		return this.numberOfBallsEnum[type.gridSize.numberOfBalls]
	}

	/** @method saveItem Saves the the item operated on by this form, then navigates back to the list for this item. */
	protected saveItem() : void{
		if(!this.quantity[this.item.id] || this.quantity[this.item.id] <= 0) {
			this.$emit("addGame", this.item);
			return;
		}
		for(let i = this.quantity[this.item.id]; i > 0; i--) {
			this.$emit("addGame", this.item);
		}
	}

	private pickItem(index: number, item: Game): void {
		this.item = item;
		this.saveItem();
	}

}
</script>

<style scoped>

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	transition: opacity .3s ease;
}

.modal-wrapper {
	vertical-align: middle;
}

.modal-container {
	width: 80%;
	margin: 0px auto;
	padding: 20px 30px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
	transition: all .3s ease;
}

.modal-body {
	margin: 0;
}

.modal-default-button {
	float: right;
}

/*
 * The following styles are auto-applied to elements with transition="modal" when their visibility is toggled by Vue.js.
 * You can easily play with the modal transition by editing these styles.
 */

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

/* .el-select-dropdown {
	z-index: 12005 !important;
} */

</style>

.wrapper{
	position: relative;
	top: 0;
	height: 100vh;

	&:after{
		display: table;
		clear: both;
		content: " ";
	}

	&.wrapper-full-page{
		height: auto;
		min-height: 100vh;

		.navbar {
			max-height: none;
		}
		.navbar-nav .nc-icon{
			margin-top: 10px;
		}
	}
}

.full-page{

	&:after,
	&:before{
		display: block;
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 2;
	}

	&:before{
		opacity: .33;
		background: #000000;
	}

	&:after{
		@include icon-gradient($black-color-filter-top, rgba($black-color-filter-bottom,.4));
		z-index: 3;
		opacity: .55;
	}

	> .content,
	> .footer{
		position: relative;
		z-index: 4;
	}

	> .content{
		min-height: calc(100vh - 70px);
	}

	.full-page-background{
		position: absolute;
		z-index: 1;
		height: 100%;
		width: 100%;
		display: block;
		top: 0;
		left: 0;
		background-size: cover;
		background-position: center center;

	}

	&[data-image]:after,
	&.has-image:after{
		opacity: .9;
	}

	&[data-color="blue"]:after{
		@include icon-gradient($color-blue, rgba($blue-color-bottom,.6));
	}
	&[data-color="azure"]:after{
		@include icon_gradient($color-azure, rgba($azure-color-bottom,.6));
	}
	&[data-color="green"]:after{
		@include icon_gradient($color-green, rgba($green-color-bottom,.6));
	}
	&[data-color="orange"]:after{
		@include icon_gradient($color-orange, rgba($orange-color-bottom,.6));
	}
	&[data-color="red"]:after{
		@include icon_gradient($color-red, rgba($red-color-bottom,.6));
	}
	&[data-color="purple"]:after{
		@include icon_gradient($color-purple, rgba($purple-color-bottom,.6));
	}
	&[data-color="black"]:after{
		@include icon_gradient($black-color-filter-top, $black-color-filter-bottom);
	}

	.footer nav > ul a:not(.btn),
	.footer,
	.footer .copyright a{
		color: $white-color;
		font-size: $font-size-base;
	}

}

.login-page,
.lock-page{
	> .content{
		padding-top: 12vh;
	}
}

.login-page{
	.card{
		box-shadow: 0 25px 30px -13px rgba(40, 40, 40, 0.4);
		border-radius: $border-radius-extreme;
		padding-top: $padding-default-horizontal * 2;
		padding-bottom: $padding-default-horizontal * 2;

		.header{
			padding-bottom: $padding-default-horizontal * 2;
		}

		.btn-wd{
			min-width: 180px;
		}
	}
}

.lock-page{

	.user-profile{
		text-align: center;
		width: 240px;
		margin: 30px auto 0;
		color: #FFFFFF;
		position: absolute;
		left: 50%;
		margin-left: -120px;
		display: block;

		&.with-animation{
			@include transition(300ms, $transition-ease-in);
		}

		.author{
			border-radius: 50%;
			width: 100px;
			height: 100px;
			border: 4px solid rgba($white-color,.3);
			overflow: hidden;
			margin: 0 auto;
			@include transition(300ms, $transition-linear);

			img{
				width: 100%;
			}
		}

		h4{
			margin-top: $margin-base;
			margin-bottom: ($margin-base * 2);
		}

	}

}

.register-page{

	.header-text{
		color: #FFFFFF;
		text-align: center;
		padding: ($padding-default-horizontal * 3) 0 ($padding-default-horizontal * 2);

		h4{
			margin-top: 10px;
		}

		hr{
			opacity: .3;
			margin-top: ($margin-base * 2);
		}
	}

	.media{
		color: $white-color;
		margin-bottom: ($margin-base * 3);

		.icon{
			float: left;
			margin-right: 5px;
		}

		i{
			font-size: $font-size-h2;
		}

		h4{
			margin: 0 0 5px 15px;
		}
		p {
			margin-left: 15px;
		}
	}

	.form-group{
		margin-bottom: ($margin-base + 10px);
	}

	.card{
		.content{
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

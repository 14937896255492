<template>
	<card>
		<button type="button" class="btn btn-wd btn-primary btn-fill float-right" @click="addItem()"><icon :icon="['far', 'plus']" fixed-width size="lg" /> {{ localize("Add") }}</button>
		<game-picker
 			v-if="showModal"
			@close="showModal = false"
			v-on:addGame="addGame"
		></game-picker>
		<b-table
			id="gameList"
			:items="sortableList"
			:fields="fields"
			show-empty
			striped
			hover
			small
			:empty-text="emptyResultText"
			:label-sort-asc="localize('Click to sort Ascending')"
			:label-sort-desc="localize('Click to sort Descending')"
			:empty-filtered-text="localize('There are no items matching your request')"
		>
			<template v-slot:cell(startTime)="row">
				{{ getStartTime(row.item, row.index) }}
			</template>
			<template v-slot:cell(name)="row">
				<span
					:title="'Id: ' + row.item"
				>{{ getName(row.item) }}</span>
			</template>
			<template v-slot:cell(color)="row">
				<div
					class="colorPatch"
					:style="{
						background: getGameColor(row.item)
					}"
				> </div>
			</template>
			<template v-slot:cell(actions)="row">
				<a
					v-b-tooltip.hover.top="localize('Delete')"
					class="btn-link btn-danger"
					@click.stop="deleteItem(row.index, row.item)"
				>
					<icon :icon="['far', 'trash-alt']" fixed-width size="lg" />
				</a>
				<a
					v-b-tooltip.hover.top="localize('Order') + ' - ' + localize('drag\'n drop')"
					class="btn-link btn-primary"
				>
					<icon :icon="['fas', 'bars']" fixed-width size="lg" />
				</a>
			</template>
		</b-table>
	</card>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, ColorUtilities, TimeSpan, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import GamePicker from './GamePicker.vue';
import { IGamePlan as GamePlan, IGame as Game, IPrizePatternGroup as PrizePatternGroup, ISheetType as SheetType, IGridType as GridType, IGridSize as GridSize } from '@/services/api';
import Sortable from 'sortablejs';

let tinycolor = require("tinycolor2");


@Component({
	components: {
		Card,
		"game-picker": GamePicker
	},
})
export default class GameList extends BaseList {
	private showModal: boolean = false;
	private sortableList: Array<number> = new Array<number>();
	private startTime: string = "00:00:00";

	@Prop({ type: Array, default: new Array<number>() })
	public gameIds: Array<number>;

	@Watch("gameIds")
	public reapplySortable(val: Array<number>, oldVal: Array<number>): void {
		if(this.sortableList.length <= 0) this.sortableList = val;
	}

	public mounted() {
		this.sortableList = this.gameIds.filter((item: number) => item);	// clone gameIds
		let listElement: HTMLElement = document.getElementById('gameList').querySelector('tbody');
		let self = this;
		self.$nextTick(() => {
			let sortable = Sortable.create(listElement, { onEnd: self.moveItem });
		});
	}

	private fields: Array<any> = [
		{
			key: "startTime",
			label: this.localize("Start"),
			sortable: false
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: false
		},
		{
			key: "color",
			label: this.localize("Color"),
			sortable: false
		},
		{
			key: "type",
			label: this.localize("Type"),
			formatter: this.typeFormatter,
			sortable: false
		},
		{
			key: "drawDelay",
			label: this.localize("Draw Delay"),
			formatter: this.drawDelayFormatter,
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private typeFormatter(value: any, key: string, item: number): string {
		let game: Game = this.gameList.find((element: Game) => element.id == item);
		if(!game) return "";
		let sheet: SheetType = this.sheetTypeList.find((elemenmt: SheetType) => elemenmt.id == game.sheetTypeIds[0]);
		if(!sheet) return "";
		let type: GridType = this.gridTypeList.find((element: GridType) => element.id == sheet.gridTypeId);
		if(!type || !type.gridSize) return "";
		return this.numberOfBallsEnum[type.gridSize.numberOfBalls];
	}

	private drawDelayFormatter(value: any, key: string, item: number): string {
		let dur: TimeSpan = new TimeSpan();
		let game: Game = this.gameList.find((element: Game) => element.id == item);
		if(game && game.drawInfo && game.drawInfo.normalDrawDelay) dur = TimeSpan.parse(game.drawInfo.normalDrawDelay, "HH:MM:SS.FF");
		return dur.toString("S.f");
	}

	private getStartTime(value: any, index: number): string {
		let startTime = TimeSpan.parse(this.startTime);
		for(let i: number = 0; i < index; i++) {
			let game: Game = this.gameList.find((element: Game) => element.id == this.gameIds[i]);
			if(game) startTime = startTime.add(TimeSpan.parse(game.maxDuration));
		}
		return startTime.toString("HH:MM");	// Dropping seconds in this presentation
	}

	private getName(value: number): string {
		let game: Game = this.gameList.find((element: Game) => element.id == value);
		if(!game) return "";
		return game.name;
	}

	private getGameColor(value: number): string {
		let game: Game = this.gameList.find((element: Game) => element.id == value);
		if(!game) return "";
		let tColor = tinycolor(ColorUtilities.argbToVueColor(game.color).rgba);
		return tColor.toHexString();
	}

	/** @method addItem Navigates the user to a form to create a new item for the current list. */
	protected addItem(): void {
		this.showModal = true;
	}

	/** @method deleteItem Deletes the item in the list
	 * @param index The list index of the item to delete. This is used remove the item from locally cached data.
	 * @param item The item to delete. */
	protected deleteItem(index: number, item: { id: number}, type: string = ""): void {
		if (!confirm(this.localize("Are you sure you want to delete this item?"))) return;
		this.gameIds.splice(index, 1);
		Logger.log(LogLevel.Info, "User removed " + (<Game>item).name + " to the GamePlan.");
	}

	private moveItem(e: { newIndex: number, oldIndex:number }): void {
		let clonedItems: Array<number> = new Array<number>(...this.sortableList);	// clone array
		clonedItems.splice(e.newIndex, 0, clonedItems.splice(e.oldIndex, 1)[0]);
		this.sortableList = new Array<number>();
		this.$nextTick(() => {
			this.sortableList = clonedItems;
			this.$emit("changed", clonedItems);
		});		
		Logger.log(LogLevel.Info, "User moved a Game in the Game Plan.");
	}

	private addGame(value: Game): void {
		this.gameIds.push(value.id);
		this.showModal = false;
		Logger.log(LogLevel.Info, "User added " + value.name + " to the GamePlan.");
	}

}
</script>

<style scoped>

ul{
	list-style: none;
	margin:0;
	padding:0;
}

li {
	display: block;
	position: relative;
	margin-right:3px;
}

.colorPatch{
	width:100%;
	height:100%;
	min-height:20px;
}

div.multiplier {
	display: block;
	position: absolute;
	padding-left:3px;
	font-size: 1.3em;
	font-weight: bold;
	color:#fff;
   -webkit-text-stroke-width: 1px;
   -webkit-text-stroke-color: black;	
}

div.price {
	display: inline-block;
	position: relative;
	top:-8px;
	left:5px;
	font-size: 1.1em;
}

td[aria-colindex="5"] {
	text-align: center;
	background: #f44;
}
</style>

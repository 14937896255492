<template>
	<card :title="localize('Rooms')">
		<a
			class="btn btn-add float-right"
			@click.prevent="addItem()"
			:title="localize('Add new Item') + ' (' + localize('Shortcut') + ': Ctrl + )'"
		>
			<icon :icon="['far', 'plus']" fixed-width size="lg" />
		</a>
		<b-table
			:items="roomList"
			:fields="fields"
			:sort-by="'id'"
			:sort-desc="false"
			show-empty
			striped
			:empty-text="emptyResultText"
			:label-sort-asc="localize('Click to sort Ascending')"
			:label-sort-desc="localize('Click to sort Descending')"
			:empty-filtered-text="localize('There are no items matching your request')"
		>
			<template v-slot:cell(status)="row">
				<span>{{ localize(roomStateEnum[(row.item.status || {}).state]) }}</span>
			</template>
			<template v-slot:cell(actions)="row">
				<a
					v-b-tooltip.hover.top="localize('Start')"
					class="btn-info btn-link"
					@click.stop="open(row.item)"
					:disabled="!row.item.status || row.item.status.state != roomStateEnum.Closed"
				>
					<icon :icon="['fas', 'play']" fixed-width size="lg" />
				</a>
				<a
					v-b-tooltip.hover.top="localize('Stop')"
					class="btn-info btn-link"
					@click.stop="close(row.item)"
					:disabled="!row.item.status || row.item.status.state != roomStateEnum.Open"
				>
					<icon :icon="['fas', 'stop']" fixed-width size="lg" />
				</a>
				<a
					v-b-tooltip.hover.top="localize('Force Stop')"
					class="btn-info btn-link"
					@click.stop="forceClose(row.item)"
					:disabled="!row.item.status"
				>
					<icon :icon="['fad', 'stop']" fixed-width size="lg" />
				</a>
				<a
					v-b-tooltip.hover.top="localize('Restart')"
					class="btn-info btn-link"
					@click.stop="restart(row.item)"
					:disabled="!row.item.status || row.item.status.state == roomStateEnum.Closing"
				>
					<icon :icon="['far', 'sync']" fixed-width size="lg" />
				</a>
				<a
					v-b-tooltip.hover.top="(row.item.state == roomStateEnum.Running || row.item.state == roomStateEnum.Restarting)?localize('Stop to enable Edit'):localize('Edit')"
					class="btn-info btn-link"
					@click.stop="editItem(row.item)"
				>
					<icon :icon="['far', 'edit']" fixed-width size="lg" />
				</a>
				<a
					v-b-tooltip.hover.top="(row.item.state != roomStateEnum.Stopped)?localize('Stop to enable Delete'):localize('Delete')"
					class="btn-danger btn-link"
					@click.stop="deleteItem(row.index, row.item)"
				>
					<icon :icon="['far', 'trash-alt']" fixed-width size="lg" />
				</a>
			</template>
		</b-table>
	</card>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel, RoomWithStatus } from '@/models';
import { RoomService } from '@/services';
import Card from '@/components/lbd/Cards/Card.vue';
import { IRoom as Room, IGameSchedule as Schedule, RoomStatus, RoomState } from '@/services/api';


@Component({
	name: 'RoomList',
	components: {
		Card
	}
})
export default class RoomList extends BaseList {
	private roomService: RoomService = new RoomService();

	public created() {
		this.getRoomStatuses();
	}

	@Watch("roomList")
	private getRoomStatuses() {
		this.roomList.forEach((element: RoomWithStatus) => {
			this.roomService.roomStatus(element.id)
				.then((status: RoomStatus): void =>{
					this.$store.commit("cacheStore/setRoomStatus", status);
				})
		});
	}

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: true
		},
		{
			key: "scheduleIds",
			label: this.localize("Schedule"),
			formatter: this.scheduleFormatter
		},
		{
			key: "status",
			label: this.localize("Status")
		},
		{
			key: "actions",
			label: this.localize("Actions")
		}
	];

	private scheduleFormatter(value: Array<number>, key: string, item: Room): string {
		let name: string = this.localize("undefined");
		let schedule = this.scheduleList.find((element: Schedule) => element.id == item.scheduleId);
		if(schedule) name = schedule.name;
		return name;
	}

	private open(room: RoomWithStatus): void {
		if(!room.status) return;
		if(room.status.state != RoomState.Closed) return;
		this.roomService.openRoom(room.id)
			.then(() => {
				this.getRoomStatuses();
			});
	}

	private close(room: RoomWithStatus): void {
		if(!room.status) return;
		if(room.status.state != RoomState.Open) return;
		this.roomService.closeRoom(room.id)
			.then(() => {
				this.getRoomStatuses();
			});
	}

	private forceClose(room: RoomWithStatus): void {
		if(!room.status) return;
		this.roomService.forceCloseRoom(room.id)
			.then(() => {
				this.getRoomStatuses();
			});
	}

	private restart(room: RoomWithStatus): void {
		if(!room.status) return;
		if(room.status.state != RoomState.Open && room.status.state != RoomState.Closed) return;
		this.roomService.restartRoom(room.id)
			.then(() => {
				this.getRoomStatuses();
			});
	}

}
</script>

<style scoped>

.controls{
	margin: 0 12px;
}

a[disabled] {
	cursor: default;
	color:#424242;
}

.btn-link[disabled] {
	color:#424242 !important;
}

</style>
